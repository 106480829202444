import { message } from 'antd';
import axios from 'axios';
import firebase from 'firebase/app';
import fileDownload from 'js-file-download';
import AppConfig from '../config/config';
import { ChargeInfo, Order, OrderCount, StatusCount } from '../models/order';
import { Pagination } from '../models/pagination';

const api = axios.create({
  baseURL: AppConfig.apiUrl,
  timeout: AppConfig.timeout,
});

export default class OrderService {
  static getOrder = async function (id: string | number): Promise<Order> {
    try {
      const token = await firebase.auth().currentUser?.getIdToken();
      const response = await api.get('/get_order', {
        headers: token ? { authorization: `Bearer ${token}` } : undefined,
        params: { id: id },
      });
      return response.data;
    } catch (e) {
      throw e;
    }
  };

  static getOrderCount = async function (params: { userId: number }): Promise<OrderCount[]> {
    try {
      const response = await api.get('/get_order_count', { headers: { authorization: `Bearer ${await firebase.auth().currentUser?.getIdToken()}` }, params });
      return response.data;
    } catch (e) {
      if (e?.response?.status == 400) message.warn('請稍後再試');
      throw e;
    }
  };

  static getOrders = async function (params: {
    senderId?: number;
    status?: string;
    pickupDistrict?: string;
    deliverDistrict?: string;
    createdAt?: string;
    bookmarked?: boolean;
    limit: number;
    offset: number;
    orderBy: string;
    sort?: string;
  }): Promise<{ pagination: Pagination; statusCount: StatusCount[]; orders: Order[] }> {
    try {
      const response = await api.get('/get_orders', { headers: { authorization: `Bearer ${await firebase.auth().currentUser?.getIdToken()}` }, params });
      return response.data;
    } catch (e) {
      if (e?.response?.status == 400) message.warn('請稍後再試');
      throw e;
    }
  };

  static createOrder = async function (body: any): Promise<Order> {
    try {
      const response = await api.post('/create_order', body, { headers: { authorization: `Bearer ${await firebase.auth().currentUser?.getIdToken()}` } });
      return response.data;
    } catch (e) {
      if (e?.response?.status == 400) message.warn('請稍後再試');
      throw e;
    }
  };

  static validateOrders = async function (body: any): Promise<any[]> {
    try {
      const response = await api.post('/validate_orders', body, { headers: { authorization: `Bearer ${await firebase.auth().currentUser?.getIdToken()}` } });
      return response.data;
    } catch (e) {
      if (e?.response?.status == 400) message.warn('請稍後再試');
      throw e;
    }
  };

  static createOrders = async function (body: any): Promise<Order> {
    try {
      const response = await api.post('/create_orders', body, { headers: { authorization: `Bearer ${await firebase.auth().currentUser?.getIdToken()}` } });
      return response.data;
    } catch (e) {
      if (e?.response?.status == 400) message.warn('請稍後再試');
      throw e;
    }
  };

  static updateOrder = async function (body: any): Promise<Order> {
    try {
      const response = await api.post('/update_order', body, { headers: { authorization: `Bearer ${await firebase.auth().currentUser?.getIdToken()}` } });
      return response.data;
    } catch (e) {
      if (e?.response?.status == 400) message.warn('請稍後再試');
      throw e;
    }
  };

  static updateOrderPaymentChannel = async function (body: { id: string | number; paymentChannel: string }): Promise<Order> {
    try {
      const response = await api.post('/update_order_payment_channel', body, { headers: { authorization: `Bearer ${await firebase.auth().currentUser?.getIdToken()}` } });
      return response.data;
    } catch (e) {
      if (e?.response?.status == 400) message.warn('請稍後再試');
      throw e;
    }
  };

  static updateStatus = async function (body: { id: number; status: string }): Promise<Order> {
    try {
      const response = await api.post('/update_order_status', body, { headers: { authorization: `Bearer ${await firebase.auth().currentUser?.getIdToken()}` } });
      return response.data;
    } catch (e) {
      if (e?.response?.status == 400) message.warn('請稍後再試');
      throw e;
    }
  };

  static cancelOrder = async function (id: string | number): Promise<Order> {
    try {
      const response = await api.post('/cancel_order', { id }, { headers: { authorization: `Bearer ${await firebase.auth().currentUser?.getIdToken()}` } });
      return response.data;
    } catch (e) {
      if (e?.response?.status == 400) message.warn('請稍後再試');
      throw e;
    }
  };

  static bookmarkOrder = async function (body: { orderId: number }): Promise<Order> {
    try {
      const response = await api.post('/bookmark_order', body, { headers: { authorization: `Bearer ${await firebase.auth().currentUser?.getIdToken()}` } });
      return response.data;
    } catch (e) {
      if (e?.response?.status == 400) message.warn('請稍後再試');
      throw e;
    }
  };

  static downloadWaybill = async function (id: number): Promise<void> {
    try {
      const response = await api.get('/download_waybill', { headers: { authorization: `Bearer ${await firebase.auth().currentUser?.getIdToken()}` }, params: { id }, responseType: 'blob' });
      return fileDownload(response.data, response.headers['content-disposition'].replace('attachment; filename=', ''));
    } catch (e) {
      if (e?.response?.status == 400) message.warn('請稍後再試');
      throw e;
    }
  };

  static downloadWaybills = async function (params: { ids: number[] }): Promise<void> {
    try {
      const response = await api.get('/download_waybills', {
        headers: { authorization: `Bearer ${await firebase.auth().currentUser?.getIdToken()}` },
        params: { ids: params.ids.join(',') },
        responseType: 'blob',
      });
      return fileDownload(response.data, `WB${Date.now()}.zip`);
    } catch (e) {
      if (e?.response?.status == 400) message.warn('請稍後再試');
      throw e;
    }
  };

  static approveOrderPayment = async function (body: { id: number }): Promise<Order> {
    try {
      const response = await api.post('/approve_order_payment', body, { headers: { authorization: `Bearer ${await firebase.auth().currentUser?.getIdToken()}` } });
      return response.data;
    } catch (e) {
      if (e?.response?.status == 400) message.warn('請稍後再試');
      throw e;
    }
  };

  static rejectOrderPayment = async function (body: { id: number; reason: string }): Promise<Order> {
    try {
      const response = await api.post('/reject_order_payment', body, { headers: { authorization: `Bearer ${await firebase.auth().currentUser?.getIdToken()}` } });
      return response.data;
    } catch (e) {
      if (e?.response?.status == 400) message.warn('請稍後再試');
      throw e;
    }
  };

  static getOrderChargeDetail = async function (body: any): Promise<ChargeInfo> {
    try {
      const response = await api.post('/get_order_charge_details', body, { headers: { authorization: `Bearer ${await firebase.auth().currentUser?.getIdToken()}` } });
      return response.data;
    } catch (e) {
      if (e?.response?.status == 400) message.warn('請稍後再試');
      throw e;
    }
  };

  static assignDriver = async function (body: { ids: number[]; driverId: number }): Promise<String> {
    try {
      const response = await api.post('/assign_orders_driver', body, { headers: { authorization: `Bearer ${await firebase.auth().currentUser?.getIdToken()}` } });
      return response.data;
    } catch (e) {
      if (e?.response?.status == 400) message.warn('請稍後再試');
      throw e;
    }
  };

  static unassignDriver = async function (body: { ids: number[]; driverId: number }): Promise<String> {
    try {
      const response = await api.post('/unassign_orders_driver', body, { headers: { authorization: `Bearer ${await firebase.auth().currentUser?.getIdToken()}` } });
      return response.data;
    } catch (e) {
      if (e?.response?.status == 400) message.warn('請稍後再試');
      throw e;
    }
  };

  static requireReplenishment = async function (body: { id: number; replenishCharge: number; replenishReason: string }): Promise<Order> {
    try {
      const response = await api.post('/order_require_replenishment', body, { headers: { authorization: `Bearer ${await firebase.auth().currentUser?.getIdToken()}` } });
      return response.data;
    } catch (e) {
      if (e?.response?.status == 400) message.warn('請稍後再試');
      throw e;
    }
  };

  static downloadExcel = async function (): Promise<void> {
    try {
      const response = await api.get('/download_excel', { responseType: 'blob' });
      return fileDownload(response.data, `${AppConfig.appName}_order_template.xlsx`);
    } catch (e) {
      if (e?.response?.status == 400) message.warn('請稍後再試');
      throw e;
    }
  };
}
