import { message } from 'antd';
import axios from 'axios';
import firebase from 'firebase/app';
import AppConfig from '../config/config';
import { Pricing } from '../models/pricing';

const api = axios.create({
  baseURL: AppConfig.apiUrl,
  timeout: AppConfig.timeout,
});

export default class PricingService {
  static getPricings = async function (): Promise<Pricing[]> {
    try {
      const response = await api.get('/get_pricings');
      return response.data;
    } catch (e) {
      if (e?.response?.status == 400) message.warn('請稍後再試');
      return [];
    }
  };

  static getPricingsByUser = async function (userId: number): Promise<Pricing[]> {
    try {
      const response = await api.get('/get_pricings_by_user', { headers: { authorization: `Bearer ${await firebase.auth().currentUser?.getIdToken()}` }, params: { userId } });
      return response.data;
    } catch (e) {
      if (e?.response?.status == 400) message.warn('請稍後再試');
      return [];
    }
  };

  static updatePricings = async function (body: any[]): Promise<Pricing[]> {
    try {
      const response = await api.post('/update_pricings', body, { headers: { authorization: `Bearer ${await firebase.auth().currentUser?.getIdToken()}` } });
      return response.data;
    } catch (e) {
      if (e?.response?.status == 400) message.warn('請稍後再試');
      throw e;
    }
  };

  static createPricings = async function (body: any[]): Promise<Pricing[]> {
    try {
      const response = await api.post('/create_pricings', body, { headers: { authorization: `Bearer ${await firebase.auth().currentUser?.getIdToken()}` } });
      return response.data;
    } catch (e) {
      if (e?.response?.status == 400) message.warn('請稍後再試');
      throw e;
    }
  };
  static deletePricings = async function (body: { ids: number[] }): Promise<Pricing[]> {
    try {
      const response = await api.post('/delete_pricings', body, { headers: { authorization: `Bearer ${await firebase.auth().currentUser?.getIdToken()}` } });
      return response.data;
    } catch (e) {
      if (e?.response?.status == 400) message.warn('請稍後再試');
      throw e;
    }
  };
}
