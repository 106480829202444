import Icon from '@ant-design/icons';
import { AppTheme } from '@logistic/common';
import { Button, Modal, Row, Typography } from 'antd';
import { ReactComponent as AppLogo } from 'assets/img/logo.svg';
import React, { ReactNode } from 'react';

export const ResultModal = (props: { visible: boolean; onCancel: () => void; title: string; subtitle: string; resultId?: string; customAction?: ReactNode }) => {
  const {
    visible,
    onCancel,
    title,
    subtitle,
    resultId,
    customAction = (
      <Button style={{ width: 240 }} type="primary" size="large" block onClick={() => onCancel()}>
        {'確認'}
      </Button>
    ),
  } = props;
  return (
    <Modal width={AppTheme.modalWidthSm} visible={visible} title={null} centered footer={null} onCancel={onCancel} destroyOnClose closable={false} keyboard={false} maskClosable={false}>
      <div style={{ height: 404, flexDirection: 'column', display: 'flex', alignItems: 'center' }}>
        <Row style={{ marginTop: -AppTheme.insetMd, width: 150, height: 150, backgroundColor: AppTheme.secondayColor }} align="middle" justify="center">
          <Icon component={AppLogo} style={{ fontSize: 100 }} />
        </Row>
        <Typography.Title style={{ marginTop: 50 }} level={2}>
          {title}
        </Typography.Title>
        <Typography.Text style={{ marginTop: AppTheme.insetXss, textAlign: 'center' }} type="secondary">
          {subtitle}
        </Typography.Text>
        {resultId && (
          <Typography.Title style={{ marginTop: AppTheme.insetMd, color: AppTheme.primaryColor }} level={4}>
            {resultId}
          </Typography.Title>
        )}
        <Row style={{ marginTop: 'auto', width: '100%' }} justify="center" gutter={AppTheme.insetMd}>
          {customAction}
        </Row>
      </div>
    </Modal>
  );
};
