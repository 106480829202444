import { Card, Col, Row, Space, Typography } from 'antd';
import React, { CSSProperties, ReactNode } from 'react';
import AppTheme from 'theme/theme';

const AppCard = (props: {
  bodyStyle?: CSSProperties;
  title?: string;
  subTitle?: string;
  cardTitle?: ReactNode;
  children?: ReactNode;
  actions?: ReactNode;
  bordered?: boolean;
  extra?: ReactNode;
  className?: string;
  direction?: 'horizontal' | 'vertical';
  extraSpan?: number;
  spaceSize?: number;
}) => {
  const { bodyStyle, title, subTitle, cardTitle, children, actions, bordered = false, extra, className, direction = 'vertical', extraSpan, spaceSize = AppTheme.insetMd } = props;
  return (
    <Card style={bordered ? { boxShadow: 'none' } : undefined} bodyStyle={bodyStyle} bordered={bordered} title={cardTitle} actions={actions ? [actions] : undefined} className={className}>
      <Space direction={direction} size={spaceSize}>
        {title && (
          <Row align="top" justify="space-between" gutter={AppTheme.insetLg}>
            <Col span={extraSpan ? 24 - extraSpan : undefined}>
              <Space direction="vertical" size={AppTheme.insetXss - 4}>
                <Typography.Title level={5}>{title}</Typography.Title>
                {subTitle && (
                  <Typography.Text style={{ color: '#262626' }} type="secondary">
                    {subTitle}
                  </Typography.Text>
                )}
              </Space>
            </Col>
            <Col span={extraSpan}>{extra}</Col>
          </Row>
        )}
        {children}
      </Space>
    </Card>
  );
};

export default AppCard;
