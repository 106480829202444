import { DropdownInput, LineInput, RadioInput } from '@logistic/common';
import { Button, Form, Input, InputNumber, Layout, PageHeader, Typography } from 'antd';
import React from 'react';

const FormPage = () => {
  return (
    <Layout.Content>
      <PageHeader title="Form Demo" />
      <Form>
        <Typography.Text>{'LineInput'}</Typography.Text>
        <LineInput name="LineInput1" placeholder="no label" />
        <LineInput name="LineInput2" placeholder="no label with suffix" suffix="Suffix" />
        <LineInput name="LineInput3" label="Label" placeholder="normal" />
        <LineInput name="LineInput4" label="Label" placeholder="with suffix" suffix="Suffix" />
        <Typography.Text>{'RadioInput'}</Typography.Text>
        <RadioInput
          name="RadioInput"
          initialValue={'1'}
          options={[
            { value: '1', label: '1' },
            { value: '2', label: '2' },
            { value: '3', label: '3' },
          ]}
        />
        <DropdownInput
          name="DropdownInput"
          placeholder="DropdownInput"
          options={[
            { value: '1', label: '1' },
            { value: '2', label: '2' },
            { value: '3', label: '3' },
          ]}
        />

        <Form.Item>
          <Input placeholder="prefix + suffix" prefix="Prefix" suffix="Suffix" />
        </Form.Item>
        <Typography.Text>{'InputNumber'}</Typography.Text>
        <Form.Item>
          <InputNumber placeholder="empty" />
          <Button />
        </Form.Item>
        <Form.Item>
          <InputNumber placeholder="prefix" prefix="Prefix" />
        </Form.Item>
      </Form>
    </Layout.Content>
  );
};
export default FormPage;
