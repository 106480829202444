import { AppContext, AppModal, AppTheme, EmailInput, PasswordInput } from '@logistic/common';
import { Button, Col, Form, message, Row, Space, Typography } from 'antd';
import { ClientContext } from 'App';
import React, { useContext, useState } from 'react';
import { signInWithEmailAndPassword } from 'service/firebase';

const CooperateLoginModal = () => {
  const { cooperateLoginModalVisible, setLoginModalVisible, setCooperateLoginModalVisible, setRegisterModalVisible } = useContext(ClientContext);
  const { setForgetPasswordModalVisible } = useContext(AppContext);
  const [loggingIn, setLoggingIn] = useState(false);

  const onCooperateFinish = async (values: any) => {
    try {
      setLoggingIn(true);
      await signInWithEmailAndPassword(values.email, values.password);
      localStorage.setItem('email', values.email);
      message.success('登入成功!');
      setCooperateLoginModalVisible(false);
    } catch (e) {
      if (e.code == 'auth/too-many-requests') message.warn('請稍後再試');
      if (e.code == 'auth/wrong-password') message.warn('密碼錯誤!');
    } finally {
      setLoggingIn(false);
    }
  };

  const onLogin = () => {
    setCooperateLoginModalVisible(false);
    setLoginModalVisible(true);
  };

  const onRegister = () => {
    setCooperateLoginModalVisible(false);
    setRegisterModalVisible(true);
  };

  const onForgetPassword = () => {
    setCooperateLoginModalVisible(false);
    setForgetPasswordModalVisible(true);
  };

  return (
    <AppModal title="公司登入" subtitle="登入後即可免驗證快速下單與查件" visible={cooperateLoginModalVisible} onCancel={() => setCooperateLoginModalVisible(false)} showLogo>
      <Form
        onFinish={onCooperateFinish}
        layout="horizontal"
        initialValues={{
          email: localStorage.getItem('email'),
        }}
        preserve={false}
      >
        <EmailInput name="email" label="電郵" required />
        <PasswordInput name="password" label="密碼" />
        <Row justify="end">
          <Typography.Link onClick={onForgetPassword} underline>
            {'唔記得咗密碼'}
          </Typography.Link>
        </Row>
        <Row style={{ marginTop: AppTheme.insetMd }} align={'middle'} justify={'space-between'}>
          <Col span={10}>
            <Space direction="vertical" size={AppTheme.insetXs}>
              <Typography.Link underline onClick={onLogin}>
                {'散戶登入'}
              </Typography.Link>
              <Typography.Link underline onClick={onRegister}>
                {'未有公司帳戶？開戶'}
              </Typography.Link>
            </Space>
          </Col>
          <Col span={14}>
            <Form.Item shouldUpdate>
              {({ isFieldTouched, getFieldsError }) => (
                <Button
                  size="large"
                  type="primary"
                  htmlType="submit"
                  block
                  disabled={!isFieldTouched('password') || !!getFieldsError().filter(({ errors }) => errors.length).length}
                  loading={loggingIn}
                >
                  {'登入'}
                </Button>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </AppModal>
  );
};

export default CooperateLoginModal;
