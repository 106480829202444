export const validationError = {
  'Missing senderName': '必需輸入寄件人姓名',
  'Missing senderMobile': '必需輸入寄件人手機號碼',
  'Invalid senderMobile': '寄件人手機號碼不正確',
  'Missing pickupChannel': '必需輸入收件方式',
  'Missing pickupDistrict': '必需輸入收件地區',
  'Invalid pickupDistrict': '收件地區不正確',
  'Missing pickupAddress': '必需輸入收件地址',
  'Missing pickupMtrStation': '必需輸入收件地鐵站',
  'Invalid pickupMtrStation': '收件地鐵站不正確',
  'Missing receiverName': '必需輸入收件人姓名',
  'Missing receiverMobile': '必需輸入收件人手機號碼',
  'Invalid receiverMobile': '收件人手機號碼不正確',
  'Missing deliverChannel': '必需輸入派件方式',
  'Missing deliverDistrict': '必需輸入派件地區',
  'Invalid deliverDistrict': '派件地區不正確',
  'Missing deliverAddress': '必需輸入派件地址',
  'Missing deliverMtrStation': '必需輸入派件地鐵站',
  'Invalid deliverMtrStation': '派件地鐵站不正確',
  'Missing goods': '必需輸入貨物',
  'Invalid goods': '貨物不正確',
  'Goods - Missing description': '必需輸入貨物內容',
  'Goods - Missing weight': '必需輸入貨物重量',
  'Goods - Invalid weight': '貨物重量不正確',
};
