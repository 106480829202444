import { AppModal, AppTheme, EmailInput, LineInput, PasswordInput, PhoneInput, UserService } from '@logistic/common';
import { Button, Col, Form, message, Row, Space, Typography } from 'antd';
import { ClientContext } from 'App';
import React, { useContext, useState } from 'react';

const RegisterModal = () => {
  const { registerModalVisible, setLoginModalVisible, setCooperateLoginModalVisible, setRegisterModalVisible } = useContext(ClientContext);
  const [loading, setLoading] = useState(false);

  const onFinish = async (values: any) => {
    try {
      setLoading(true);
      await UserService.register(values);
      message.success('登記成功!');
      setRegisterModalVisible(false);
    } finally {
      setLoading(false);
    }
  };

  const onLogin = () => {
    setRegisterModalVisible(false);
    setLoginModalVisible(true);
  };

  const onCooperateLogin = () => {
    setRegisterModalVisible(false);
    setCooperateLoginModalVisible(true);
  };

  return (
    <AppModal title="公司登記" subtitle="開戶後即可免驗證快速下單與查件" visible={registerModalVisible} onCancel={() => setRegisterModalVisible(false)} showLogo>
      <Form onFinish={onFinish} layout="horizontal" preserve={false}>
        <LineInput name="name" label="公司名稱" required />
        <PhoneInput name="phone" label="電話" placeholder="輸入日間聯絡電話號碼" />
        <EmailInput name="email" label="電郵地址" required />
        <LineInput name="address" label="公司地址" />
        <PasswordInput name="password" label="密碼" placeholder="輸入最少6位字元的密碼" />
        <PasswordInput
          name="confirmPassword"
          label="重複密碼"
          placeholder="再次輸入密碼"
          dependencies={['password']}
          extraRules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) return Promise.resolve();
                return Promise.reject('與密碼不相符');
              },
            }),
          ]}
        />
        <Row align={'middle'} justify={'space-between'}>
          <Col span={10}>
            <Space direction="vertical" size={AppTheme.insetXs}>
              <Typography.Link underline onClick={onLogin}>
                {'散戶登入'}
              </Typography.Link>
              <Row>
                <Typography.Text>{'已有公司帳戶？'}</Typography.Text>
                <Typography.Link underline onClick={onCooperateLogin}>
                  {'登入'}
                </Typography.Link>
              </Row>
            </Space>
          </Col>
          <Col span={14}>
            <Form.Item shouldUpdate>
              {({ isFieldsTouched, getFieldsError }) => (
                <Button
                  size="large"
                  type="primary"
                  htmlType="submit"
                  block
                  disabled={!isFieldsTouched(['name', 'email', 'password', 'confirmPassword'], true) || !!getFieldsError().filter(({ errors }) => errors.length).length}
                  loading={loading}
                >
                  {'登記'}
                </Button>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </AppModal>
  );
};

export default RegisterModal;
