import { LoadingOutlined } from '@ant-design/icons';
import { Card, DatePicker, Pagination, Row, Select, Space, Table, TablePaginationConfig, Typography } from 'antd';
import { ColumnsType, SorterResult } from 'antd/lib/table/interface';
import { CloseIcon, DropdownOpenIcon } from 'components/AppIcon';
import { AppConfigProvider } from 'components/AppLayout';
import { DeliverRecord } from 'models/deliverRecord';
import { Inventory, InventoryRecord } from 'models/inventory';
import moment from 'moment';
import React, { ReactNode, useState } from 'react';
import { Order } from '../../models/order';
import { Pagination as PageginationModal } from '../../models/pagination';
import { Payment } from '../../models/payment';
import { Admin, Driver, User } from '../../models/user';
import AppTheme from '../../theme/theme';

export const AppTable = <T extends Order | Payment | User | Admin | Driver | DeliverRecord | Inventory | InventoryRecord>(props: {
  data: T[];
  columns: ColumnsType<T>;
  loading: boolean;
  filter?: ReactNode;
  extra?: ReactNode;
  pagination?: PageginationModal;
  page?: number;
  pageSize?: number;
  selectable?: boolean;
  onSorterChange?: (sorter: SorterResult<T> | SorterResult<T>[]) => void;
  onPageChange?: (page: number, pageSize?: number) => void;
  extraAction?: (selected: T[], clearSelected: () => void) => ReactNode;
}) => {
  const { data, columns, loading, filter, extra, pagination, page, pageSize, selectable, onSorterChange, onPageChange, extraAction } = props;
  const [selected, setSelected] = useState<T[]>([]);

  const onTableChange = (_pagination: TablePaginationConfig, _filters: any, sorter: SorterResult<T> | SorterResult<T>[]) => {
    if (sorter) {
      if (onSorterChange) {
        onSorterChange(sorter);
      }
      setSelected([]);
    }
  };

  return (
    <AppConfigProvider renderEmpty={() => <Typography.Text type="secondary">{'沒有資料'}</Typography.Text>}>
      <Space direction="vertical">
        {filter && (
          <Row align="top" justify="space-between">
            {filter}
            {extra}
          </Row>
        )}
        <Card bodyStyle={{ padding: 0, paddingBottom: AppTheme.insetMd }} bordered={false}>
          <Table
            loading={{ indicator: <LoadingOutlined />, spinning: loading }}
            columns={columns}
            dataSource={data}
            rowKey="id"
            rowSelection={
              selectable
                ? {
                    onChange: (_: React.Key[], selected: T[]) => {
                      setSelected(selected);
                    },
                    selectedRowKeys: selected.map((e) => e.id),
                  }
                : undefined
            }
            onChange={onTableChange}
            pagination={false}
          />
        </Card>
        {!!pagination?.total && (
          <Row align="middle" justify="space-between">
            <Typography.Text>{`共 ${pagination?.total} 項`}</Typography.Text>
            <Pagination
              current={page}
              pageSize={pageSize}
              total={pagination?.total}
              onChange={(page, pageSize) => {
                setSelected([]);
                if (onPageChange) onPageChange(page, pageSize);
              }}
              pageSizeOptions={['20', '50', '100']}
              showSizeChanger
            />
          </Row>
        )}
        {extraAction && extraAction(selected, () => setSelected([]))}
      </Space>
    </AppConfigProvider>
  );
};

export const SelectFilter = (props: { label: string; width?: number; defaultValue: string; options: { label: string; value: string }[]; onChange: (value: string) => void }) => {
  const { label, defaultValue, options, onChange } = props;
  return (
    <Row
      style={{
        backgroundColor: AppTheme.filterBackgroundColor,
        borderRadius: AppTheme.filterHeight / 2,
        height: AppTheme.filterHeight,
        paddingLeft: AppTheme.insetMd,
        paddingRight: AppTheme.insetXs,
      }}
      align="middle"
    >
      <Typography.Text type="secondary" strong>
        {label}
      </Typography.Text>
      <Select
        size="small"
        style={{ width: 150, textAlign: 'end' }}
        defaultValue={defaultValue}
        options={options}
        suffixIcon={<DropdownOpenIcon style={{ fontSize: 24, marginTop: -8 }} />}
        bordered={false}
        onChange={onChange}
      />
    </Row>
  );
};

export const DateRangeFilter = (props: { label: string; onChange: (value: [moment.Moment, moment.Moment]) => void }) => {
  const { label, onChange } = props;
  const disabledDate = (current: moment.Moment) => current.isAfter(moment());
  return (
    <Row
      style={{
        backgroundColor: AppTheme.filterBackgroundColor,
        borderRadius: AppTheme.filterHeight / 2,
        height: AppTheme.filterHeight,
        paddingLeft: AppTheme.insetMd,
        paddingRight: AppTheme.insetXs,
      }}
      align="middle"
    >
      <Typography.Text type="secondary" strong>
        {label}
      </Typography.Text>
      <DatePicker.RangePicker
        size="small"
        style={{ width: 300 }}
        format="YYYY-MM-DD"
        bordered={false}
        disabledDate={disabledDate}
        onChange={onChange}
        suffixIcon={null}
        separator={<Typography.Text type="secondary">{'至'}</Typography.Text>}
        clearIcon={<CloseIcon style={{ fontSize: 24, marginTop: -5, marginBottom: -5 }} />}
      />
    </Row>
  );
};
