import { ClientContext } from 'App';
import React, { useContext } from 'react';
import { ResultModal } from './ResultModal';

const BatchOrderResultModal = () => {
  const { batchOrderResultModalVisible, setBatchOrderResultModalVisible } = useContext(ClientContext);
  return <ResultModal visible={batchOrderResultModalVisible} onCancel={() => setBatchOrderResultModalVisible(false)} title="待付款" subtitle="落單完成，所有貨單已儲存於”貨單” " />;
};

export default BatchOrderResultModal;
