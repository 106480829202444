import React, { useEffect, useState } from 'react';
import AppConfig from '../config/config';
import { Config } from '../models/config';
import { Pricing } from '../models/pricing';
import { Store } from '../models/store';
import ConfigService from '../services/configService';
import PricingService from '../services/pricingService';
import StoreService from '../services/storeService';

type AppContextType = {
  config: Config;
  setConfig: React.Dispatch<React.SetStateAction<Config>>;
  pricing: Pricing[];
  setPricing: React.Dispatch<React.SetStateAction<Pricing[]>>;
  store: Store[];
  setStore: (store: Store[]) => void;
  storeLoaded: boolean;
  forgetPasswordModalVisible: boolean;
  setForgetPasswordModalVisible: (visible: boolean) => void;
};

export const AppContext = React.createContext<AppContextType>({
  config: {} as Config,
  setConfig: () => console.log('setConfig'),
  pricing: [],
  setPricing: () => console.log('setPricing'),
  store: [],
  setStore: () => console.log('setStore'),
  storeLoaded: false,
  forgetPasswordModalVisible: false,
  setForgetPasswordModalVisible: () => console.log('setForgetPasswordModalVisible'),
});

export const useConfig = () => {
  const [config, setConfig] = useState<Config>({} as Config);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    ConfigService.getAll().then((e) => {
      setConfig(e);
      setLoaded(true);
    });
  }, []);

  return [config, setConfig, loaded] as const;
};

export const usePricing = () => {
  const [pricing, setPricing] = useState<Pricing[]>([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    PricingService.getPricings().then((e) => {
      setPricing(e);
      setLoaded(true);
    });
  }, []);

  return [pricing, setPricing, loaded] as const;
};

export const useStore = () => {
  const [store, setStore] = useState<Store[]>([]);
  const [loaded, setLoaded] = useState(!AppConfig.storeEnabled);

  useEffect(() => {
    if (AppConfig.storeEnabled) {
      StoreService.getAll().then((e) => {
        setStore(e);
        setLoaded(true);
      });
    }
  }, []);

  return [store, setStore, loaded] as const;
};
