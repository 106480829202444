export enum UserRole {
  INDIVIDUAL_CUSTOMER = 'INDIVIDUAL_CUSTOMER',
  COOPERATE_CUSTOMER = 'COOPERATE_CUSTOMER',
  ADMIN = 'ADMIN',
  DRIVER = 'DRIVER',
}

export enum AdminRole {
  ADMIN = 'ADMIN',
  STAFF = 'STAFF',
}
