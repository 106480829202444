import { SearchOutlined } from '@ant-design/icons';
import { CheckboxOptionType, Col, Form, Input, Radio, Row, Select, Space, Typography } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { Rule } from 'antd/lib/form';
import { FormInstance, FormLayout } from 'antd/lib/form/Form';
import { NamePath } from 'antd/lib/form/interface';
import React, { CSSProperties, ReactNode, useContext } from 'react';
import AppConstant from '../../constants/constant';
import mtrStation from '../../constants/mtrStation';
import { AppContext } from '../../context/context';
import AppTheme from '../../theme/theme';
import { CloseIcon, DropdownOpenIcon } from '../AppIcon';

export const AppForm = (props: { style?: CSSProperties; layout?: FormLayout; form?: FormInstance; initialValues?: any; onFinish?: (values: any) => void; children?: ReactNode }) => {
  const { style, layout = 'horizontal', form, initialValues, onFinish, children } = props;
  return (
    <Form style={style} layout={layout} form={form} initialValues={initialValues} onFinish={onFinish}>
      {children}
    </Form>
  );
};

export const PhoneInput = (props: { label: string; name: string; placeholder?: string; readOnly?: boolean; required?: boolean; suffix?: ReactNode }) => {
  const { label, name, placeholder = `輸入${label}`, readOnly, required, suffix } = props;
  const rules: Rule[] = [
    {
      type: 'integer',
      min: 10000000,
      message: `${label}不正確`,
      transform(value) {
        return value ? Number(value) : 10000000;
      },
    },
  ];
  if (required) {
    rules.push({
      required: true,
      message: `必需輸入${label}`,
    });
  }
  return (
    <Form.Item label={label} name={name} rules={rules}>
      <Input type="tel" placeholder={placeholder} maxLength={8} readOnly={readOnly} suffix={suffix} bordered={false} />
    </Form.Item>
  );
};

export const EmailInput = (props: { label: string; name: string; placeholder?: string; required?: boolean }) => {
  const { label, name, placeholder = `輸入${label}`, required } = props;
  const rules: Rule[] = [{ type: 'email', message: `${label}不正確` }];
  if (required) {
    rules.push({
      required: true,
      message: `必需輸入${label}`,
    });
  }
  return (
    <Form.Item label={label} name={name} rules={rules}>
      <Input type="email" placeholder={placeholder} bordered={false} />
    </Form.Item>
  );
};

export const TextAreaInput = (props: { label?: string; hideLabel?: boolean; name: string; placeholder?: string; required?: boolean; extraRules?: Rule[] }) => {
  const { label, hideLabel, name, placeholder = `輸入${label}`, required, extraRules } = props;
  const rules: Rule[] = [...(extraRules ?? [])];
  if (required) {
    rules.push({
      required: true,
      message: `必需輸入${label}`,
    });
  }
  return (
    <Form.Item label={hideLabel ? undefined : label} name={name} rules={rules}>
      <Input.TextArea placeholder={placeholder} bordered={false} autoSize />
    </Form.Item>
  );
};

export const LineInput = (props: {
  name: NamePath;
  label?: string;
  fieldKey?: React.Key | React.Key[];
  placeholder?: string;
  required?: boolean;
  extraRules?: Rule[];
  suffix?: ReactNode;
  size?: SizeType;
}) => {
  const { name, label, fieldKey, placeholder = `輸入${label}`, required, extraRules, suffix, size } = props;
  const rules: Rule[] = [...(extraRules ?? [])];
  if (required) {
    rules.push({
      required: true,
      message: `必需輸入${label}`,
    });
  }
  return (
    <Form.Item name={name} label={label} fieldKey={fieldKey} rules={rules}>
      <Input size={size} placeholder={placeholder} bordered={false} suffix={suffix} />
    </Form.Item>
  );
};

export const DropdownInput = (props: {
  label?: string;
  name: string;
  placeholder?: string;
  required?: boolean;
  extraRules?: Rule[];
  options: any[];
  blankErrorMessage?: string;
  loading?: boolean;
  clearLabel?: string;
}) => {
  const { label, name, placeholder = `選擇${label}`, required, extraRules, options, blankErrorMessage, loading } = props;
  const rules: Rule[] = [...(extraRules ?? [])];
  if (required) {
    rules.push({
      required: true,
      message: blankErrorMessage ?? `必需選擇${label}`,
    });
  }
  return (
    <Form.Item label={label} name={name} rules={rules}>
      <Select
        placeholder={placeholder}
        options={options}
        suffixIcon={<DropdownOpenIcon style={{ fontSize: 24, marginTop: -8 }} />}
        dropdownStyle={{ marginRight: AppTheme.insetMd }}
        loading={loading}
        bordered={false}
        allowClear={!required}
        clearIcon={
          <div style={{ backgroundColor: AppTheme.inputBackgroundColor, marginTop: -6, marginLeft: -12 }}>
            <CloseIcon style={{ fontSize: 24 }} />
          </div>
        }
      />
    </Form.Item>
  );
};

export const MtrStationInput = (props: { label?: string; name: string; placeholder?: string; required?: boolean; extraRules?: Rule[]; blankErrorMessage?: string }) => {
  const { label, name, placeholder = `選擇${label}`, required, extraRules, blankErrorMessage } = props;
  const rules: Rule[] = [...(extraRules ?? [])];
  if (required) {
    rules.push({
      required: true,
      message: blankErrorMessage ?? `必需選擇${label}`,
    });
  }
  return (
    <Form.Item label={label} name={name} rules={rules}>
      <Select placeholder={placeholder} suffixIcon={<DropdownOpenIcon style={{ fontSize: 24, marginTop: -8 }} />} bordered={false}>
        {mtrStation.map((e) => (
          <Select.OptGroup key={`${e.line}`} label={e.line}>
            {e.station.map((s) => (
              <Select.Option key={`${e.line}${s}`} value={s}>
                {s}
              </Select.Option>
            ))}
          </Select.OptGroup>
        ))}
      </Select>
    </Form.Item>
  );
};

export const IntegerInput = (props: {
  name: NamePath;
  label?: string;
  fieldKey?: React.Key | React.Key[];
  prefix?: string;
  placeholder?: string;
  suffix?: ReactNode;
  required?: boolean;
  blankErrorMessage?: string;
  extraRules?: Rule[];
  maxLength?: number;
  min?: number;
}) => {
  const { name, label, fieldKey, prefix, placeholder = '0', suffix, required, blankErrorMessage, extraRules, maxLength, min = 0 } = props;
  const rules: Rule[] = [
    {
      type: 'integer',
      min: min,
      message: '數值不正確',
      transform(value) {
        return value ? Number(value) : 0;
      },
    },
    ...(extraRules ?? []),
  ];
  if (required) {
    rules.push({
      required: true,
      message: blankErrorMessage ?? `必需輸入${label}`,
    });
  }
  return (
    <Form.Item name={name} label={label} fieldKey={fieldKey} rules={rules}>
      <Input type="number" prefix={prefix} placeholder={placeholder} suffix={suffix} maxLength={maxLength} bordered={false} />
    </Form.Item>
  );
};

export const OtpInput = (props: { label: string; name: string }) => {
  const { name, label } = props;
  return (
    <Form.Item
      label={label}
      name={name}
      rules={[
        { required: true, min: 6, message: `必需輸入${label}` },
        {
          type: 'integer',
          message: `${label}不正確`,
          transform(value) {
            return Number(value);
          },
        },
      ]}
    >
      <Input placeholder={`輸入${label}`} maxLength={6} bordered={false} />
    </Form.Item>
  );
};

export const PasswordInput = (props: { label: string; name: string; placeholder?: string; addonAfter?: ReactNode; dependencies?: NamePath[]; extraRules?: Rule[]; requiredMessage?: string }) => {
  const { name, label, placeholder = `輸入${label}`, addonAfter, dependencies, extraRules, requiredMessage } = props;
  const rules: Rule[] = [{ required: true, min: 6, message: requiredMessage ?? `必需輸入${label}` }, ...(extraRules ?? [])];
  return (
    <Form.Item label={label} name={name} rules={rules} dependencies={dependencies}>
      <Input.Password type="password" placeholder={placeholder} addonAfter={addonAfter} bordered={false} visibilityToggle={false} />
    </Form.Item>
  );
};

export const RadioInput = (props: { name: string; options: CheckboxOptionType[]; initialValue: string; style?: CSSProperties }) => {
  const { name, options, initialValue, style } = props;
  return (
    <Form.Item style={style} name={name} initialValue={initialValue}>
      <Radio.Group options={options} />
    </Form.Item>
  );
};

export const RadioInputRow = (props: { name: NamePath; initialValue: any; label: string; options: CheckboxOptionType[]; style?: CSSProperties; onChange?: (e: any) => void }) => {
  const { name, initialValue, label, style, options, onChange } = props;
  return (
    <Form.Item style={style} name={name} initialValue={initialValue}>
      <Row align={'middle'} justify={'space-between'}>
        <Typography.Text>{label}</Typography.Text>
        <Radio.Group size="small" defaultValue={initialValue} options={options} onChange={onChange} />
      </Row>
    </Form.Item>
  );
};

export const SearchBar = (props: { form?: FormInstance; onFinish: (id: string) => void; style?: CSSProperties; placeholder: string; size?: SizeType }) => {
  const { form, onFinish, style, placeholder, size } = props;
  return (
    <Form form={form} onFinish={(values) => onFinish(values.id)} style={style}>
      <LineInput name="id" placeholder={placeholder} size={size} suffix={<SearchOutlined size={24} />} />
    </Form>
  );
};

export const SenderNameInput = () => <LineInput name="senderName" label="姓名" placeholder="輸入寄件人姓名" required />;

export const SenderMobileInput = () => <PhoneInput name="senderMobile" label="手機號碼" placeholder="輸入寄件人手機號碼" required />;

export const PickupChannelInput = () => (
  <Space direction="vertical" size={AppTheme.insetMd}>
    <Typography.Text>{'選擇寄件方式'}</Typography.Text>
    <RadioInput
      name="pickupChannel"
      initialValue={Object.keys(AppConstant.pickupChannelMap)[0]}
      options={Object.keys(AppConstant.pickupChannelMap).map((e) => ({ value: e, label: AppConstant.pickupChannelMap[e] }))}
    />
  </Space>
);

export const PickupAddressInput = () => {
  const { config, pricing } = useContext(AppContext);
  const areaPricing = pricing.filter((e) => e.type == 'AREA');
  const floorPricing = pricing.filter((e) => e.description == 'FLOOR');
  return (
    <Form.Item noStyle shouldUpdate={(prevValues, curValues) => prevValues.pickupChannel !== curValues.pickupChannel || prevValues.deliverChannel !== curValues.deliverChannel}>
      {({ getFieldValue }) => {
        const pickupChannel = getFieldValue('pickupChannel');
        if (pickupChannel == 'PICKUP')
          return (
            <Space direction="vertical" size={AppTheme.insetMd}>
              <LineInput name="pickupAddress" label="寄件地址" placeholder="輸入寄件地址" required />
              <Row gutter={AppTheme.insetSm}>
                <Col span={12}>
                  <DropdownInput name="pickupDistrict" label="地區" placeholder="地區" options={config.district.map((e) => ({ label: e, value: e }))} required />
                </Col>
                {floorPricing.length && (
                  <Col span={12}>
                    <IntegerInput name="pickupFloor" label="如屬無電梯設備的樓宇" placeholder="樓層" />
                  </Col>
                )}
              </Row>
              {areaPricing.length && <DropdownInput name="pickupArea" placeholder="如屬偏遠地區" options={areaPricing.map((e) => ({ label: e.description, value: e.description }))} />}
            </Space>
          );
        return <MtrStationInput name="pickupMtrStation" label="地鐵站" placeholder="選擇地鐵站" blankErrorMessage="必需選擇一個地鐵站" required />;
      }}
    </Form.Item>
  );
};

export const ReceiverNameInput = () => <LineInput name="receiverName" label="姓名" placeholder="輸入收件人姓名" required />;

export const ReceiverMobileInput = () => <PhoneInput name="receiverMobile" label="電話" placeholder="輸入收件人手機號碼" required />;

export const DeliverChannelInput = () => (
  <Space direction="vertical" size={AppTheme.insetMd}>
    <Typography.Text>{'選擇收件方式'}</Typography.Text>
    <RadioInput
      name="deliverChannel"
      initialValue={Object.keys(AppConstant.deliverChannelMap)[0]}
      options={Object.keys(AppConstant.deliverChannelMap).map((e) => ({ value: e, label: AppConstant.deliverChannelMap[e] }))}
    />
  </Space>
);

export const DeliverAddressInput = () => {
  const { config, pricing } = useContext(AppContext);
  const areaPricing = pricing.filter((e) => e.type == 'AREA');
  const floorPricing = pricing.filter((e) => e.description == 'FLOOR');
  return (
    <Form.Item noStyle shouldUpdate={(prevValues, curValues) => prevValues.deliverChannel !== curValues.deliverChannel}>
      {({ getFieldValue }) => {
        const deliverChannel = getFieldValue('deliverChannel');
        if (deliverChannel == 'DELIVER') {
          return (
            <Space direction="vertical" size={AppTheme.insetMd}>
              <LineInput name="deliverAddress" label="收件地址" placeholder="輸入收件地址" required />
              <Row gutter={AppTheme.insetSm}>
                {areaPricing.length && (
                  <Col span={12}>
                    <DropdownInput name="deliverDistrict" label="地區" placeholder="地區" options={config.district.map((e) => ({ label: e, value: e }))} required />
                  </Col>
                )}
                {floorPricing.length && (
                  <Col span={12}>
                    <IntegerInput name="deliverFloor" label="如屬無電梯設備的樓宇" placeholder="樓層" />
                  </Col>
                )}
              </Row>
              {areaPricing.length && <DropdownInput name="deliverArea" placeholder="如屬偏遠地區" options={areaPricing.map((e) => ({ label: e.description, value: e.description }))} />}
            </Space>
          );
        }
        return <MtrStationInput name="deliverMtrStation" label="地鐵站" placeholder="選擇地鐵站" blankErrorMessage="必需選擇一個地鐵站" required />;
      }}
    </Form.Item>
  );
};
