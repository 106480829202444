import { message } from 'antd';
import axios from 'axios';
import { AdminRole, UserRole } from 'constants/userRole';
import firebase from 'firebase/app';
import { Pagination } from 'models/pagination';
import AppConfig from '../config/config';
import { Order, StatusCount } from '../models/order';
import { Admin, Driver, User } from '../models/user';

const api = axios.create({
  baseURL: AppConfig.apiUrl,
  timeout: AppConfig.timeout,
});

export default class UserService {
  static register = async function (body: any): Promise<Order> {
    try {
      const response = await api.post('/register', body);
      await firebase.auth().signInWithEmailAndPassword(body.email, body.password);
      return response.data;
    } catch (e) {
      if (e?.response?.data?.code == 'auth/email-already-exists') message.warn('電郵已被使用');
      else if (e?.response?.status == 400) message.warn('請稍後再試');
      throw e;
    }
  };

  static getUserProfile = async function (): Promise<User> {
    try {
      const response = await api.get('/get_user_profile', { headers: { authorization: `Bearer ${await firebase.auth().currentUser?.getIdToken()}` } });
      return response.data;
    } catch (e) {
      if (e?.response?.status == 400) message.warn('請稍後再試');
      throw e;
    }
  };

  static updateUserProfile = async function (body: { name?: string; phone?: string; address?: string }): Promise<User> {
    try {
      const response = await api.post('/update_user_profile', body, { headers: { authorization: `Bearer ${await firebase.auth().currentUser?.getIdToken()}` } });
      return response.data;
    } catch (e) {
      if (e?.response?.status == 400) message.warn('請稍後再試');
      throw e;
    }
  };

  static get = async function (id: string | number): Promise<User> {
    try {
      const response = await api.get('/get_user', { headers: { authorization: `Bearer ${await firebase.auth().currentUser?.getIdToken()}` }, params: { id: id } });
      return response.data;
    } catch (e) {
      if (e?.response?.status == 400) message.warn('請稍後再試');
      throw e;
    }
  };

  static getAll = async function (params: {
    id?: number;
    role?: string;
    adminRole?: string;
    bookmarked?: boolean;
    blocked?: boolean;
    limit: number;
    offset: number;
    orderBy: string;
    sort?: string;
  }): Promise<{ pagination: Pagination; statusCount: StatusCount[]; users: User[] }> {
    try {
      const response = await api.get('/get_users', { headers: { authorization: `Bearer ${await firebase.auth().currentUser?.getIdToken()}` }, params: params });
      return response.data;
    } catch (e) {
      if (e?.response?.status == 400) message.warn('請稍後再試');
      throw e;
    }
  };

  static update = async function (body: {
    id: number;
    blocked?: boolean;
    name?: string;
    phone?: string;
    address?: string;
    email?: string;
    inventorySpreadsheetId?: string;
    inventoryEnabled?: boolean;
    monthlyBillingEnabled?: boolean;
  }): Promise<User> {
    try {
      const response = await api.post('/update_user', body, { headers: { authorization: `Bearer ${await firebase.auth().currentUser?.getIdToken()}` } });
      return response.data;
    } catch (e) {
      if (e?.response?.status == 400) message.warn('請稍後再試');
      throw e;
    }
  };

  static bookmark = async function (body: { userId: number }): Promise<User> {
    try {
      const response = await api.post('/bookmark_user', body, { headers: { authorization: `Bearer ${await firebase.auth().currentUser?.getIdToken()}` } });
      return response.data;
    } catch (e) {
      if (e?.response?.status == 400) message.warn('請稍後再試');
      throw e;
    }
  };

  static getDrivers = async function (params: {
    bookmarked?: boolean;
    blocked?: boolean;
    limit: number;
    offset: number;
    orderBy: string;
    sort?: string;
  }): Promise<{ pagination: Pagination; drivers: Driver[] }> {
    try {
      const response = await api.get('/get_drivers', { headers: { authorization: `Bearer ${await firebase.auth().currentUser?.getIdToken()}` }, params: params });
      return response.data;
    } catch (e) {
      if (e?.response?.status == 400) message.warn('請稍後再試');
      throw e;
    }
  };

  static getDriver = async function (id: string): Promise<Driver> {
    try {
      const response = await api.get('/get_driver', { headers: { authorization: `Bearer ${await firebase.auth().currentUser?.getIdToken()}` }, params: { id } });
      return response.data;
    } catch (e) {
      throw e;
    }
  };

  static updateDriver = async function (body: {
    id: number;
    name?: boolean;
    mobile?: string;
    license?: string;
    firstCharge?: number;
    firstWeight?: number;
    weightCharge?: number;
    weightUnit?: number;
    lastPaymentAt?: string;
  }): Promise<Driver> {
    try {
      const response = await api.post('/update_driver', body, { headers: { authorization: `Bearer ${await firebase.auth().currentUser?.getIdToken()}` } });
      return response.data;
    } catch (e) {
      if (e?.response?.status == 400) message.warn('請稍後再試');
      throw e;
    }
  };

  static getAdmins = async function (params: { role?: string; limit: number; offset: number; orderBy: string; sort?: string }): Promise<{ pagination: Pagination; admins: Admin[] }> {
    try {
      const response = await api.get('/get_admins', { headers: { authorization: `Bearer ${await firebase.auth().currentUser?.getIdToken()}` }, params: params });
      return response.data;
    } catch (e) {
      if (e?.response?.status == 400) message.warn('請稍後再試');
      throw e;
    }
  };

  static updateAdmin = async function (body: { id: number; name?: string; phone?: string; email?: string; role?: string }): Promise<Admin> {
    try {
      const response = await api.post('/update_admin', body, { headers: { authorization: `Bearer ${await firebase.auth().currentUser?.getIdToken()}` } });
      return response.data;
    } catch (e) {
      if (e?.response?.status == 400) message.warn('請稍後再試');
      throw e;
    }
  };

  static create = async function (body: { role: UserRole; adminRole: AdminRole; name: string; phone: string; email: string }): Promise<User> {
    try {
      const response = await api.post('/create_user', body, { headers: { authorization: `Bearer ${await firebase.auth().currentUser?.getIdToken()}` } });
      return response.data;
    } catch (e) {
      if (e?.response?.status == 400) message.warn('請稍後再試');
      if (e?.response?.data?.code == 'auth/email-already-exists') message.warn('電郵已被使用');
      throw e;
    }
  };

  static delete = async function (id: string | number): Promise<User> {
    try {
      const response = await api.get('/delete_user', { headers: { authorization: `Bearer ${await firebase.auth().currentUser?.getIdToken()}` }, params: { id: id } });
      return response.data;
    } catch (e) {
      if (e?.response?.status == 400) message.warn('請稍後再試');
      throw e;
    }
  };
}
