import { message } from 'antd';
import axios from 'axios';
import firebase from 'firebase/app';
import fileDownload from 'js-file-download';
import AppConfig from '../config/config';
import { DeliverRecord } from '../models/deliverRecord';
import { Pagination } from '../models/pagination';

const api = axios.create({
  baseURL: AppConfig.apiUrl,
  timeout: AppConfig.timeout,
});

export default class DeliverRecordService {
  static getDeliverRecords = async function (params: {
    driverId?: number;
    type?: string;
    status?: string;
    createdAt?: string;
    bookmarked?: boolean;
    limit: number;
    offset: number;
    orderBy: string;
    sort?: string;
  }): Promise<{ pagination: Pagination; deliverRecords: DeliverRecord[] }> {
    try {
      const response = await api.get('/get_deliver_records', { headers: { authorization: `Bearer ${await firebase.auth().currentUser?.getIdToken()}` }, params });
      return response.data;
    } catch (e) {
      throw e;
    }
  };

  static bookmarkDeliverRecord = async function (body: { deliverRecordId: number }): Promise<DeliverRecord> {
    try {
      const response = await api.post('/bookmark_deliver_record', body, { headers: { authorization: `Bearer ${await firebase.auth().currentUser?.getIdToken()}` } });
      return response.data;
    } catch (e) {
      if (e?.response?.status == 400) message.warn('請稍後再試');
      throw e;
    }
  };

  static downloadList = async function (ids: number[]): Promise<void> {
    try {
      const response = await api.get('/download_deliver_record_list', {
        headers: { authorization: `Bearer ${await firebase.auth().currentUser?.getIdToken()}` },
        params: { ids: ids.join(',') },
        responseType: 'blob',
      });
      return fileDownload(response.data, response.headers['content-disposition'].replace('attachment; filename=', ''));
    } catch (e) {
      if (e?.response?.status == 400) message.warn('請稍後再試');
      throw e;
    }
  };
}
