export enum OrderStatus {
  PAYMENT_PENDING = 'PAYMENT_PENDING',
  PAYMENT_PROCESSING = 'PAYMENT_PROCESSING',
  PAYMENT_REJECTED = 'PAYMENT_REJECTED',
  ASSIGNING_PICKUP_DRIVER = 'ASSIGNING_PICKUP_DRIVER',
  PICKUP_PENDING = 'PICKUP_PENDING',
  PICKUP_IN_PROGRESS = 'PICKUP_IN_PROGRESS',
  REARRANGE_PICKUP = 'REARRANGE_PICKUP',
  PICKED_UP = 'PICKED_UP',
  IN_TRANSIT = 'IN_TRANSIT',
  ARRIVED = 'ARRIVED',
  PAYMENT_REPLENISH = 'PAYMENT_REPLENISH',
  REPLENISH_PROCESSING = 'REPLENISH_PROCESSING',
  REPLENISH_REJECTED = 'REPLENISH_REJECTED',
  ASSIGNING_DELIVER_DRIVER = 'ASSIGNING_DELIVER_DRIVER',
  OUT_OF_DELIVERY = 'OUT_OF_DELIVERY',
  REARRANGE_DELIVER = 'REARRANGE_DELIVER',
  DELIVERED = 'DELIVERED',
  FAILED = 'FAILED',
  CANCELLED = 'CANCELLED',
}
