import { AppModal, AppTheme, OtpInput, PhoneInput, Utils } from '@logistic/common';
import { Button, Col, Form, message, Row } from 'antd';
import { ClientContext } from 'App';
import firebase from 'firebase/app';
import React, { useContext, useEffect, useState } from 'react';
import { auth, ConfirmationResult, RecaptchaVerifier } from 'service/firebase';

const VerifyModal = () => {
  const { user, verifyModalVisible, setVerifyModalVisible, setVerified } = useContext(ClientContext);
  const [otpSent, setOtpSent] = useState(false);
  const [sendingOtp, setSendingOtp] = useState(false);
  const [loggingIn, setLoggingIn] = useState(false);
  const [confirmationResult, setConfirmationResult] = useState<ConfirmationResult>();
  const [form] = Form.useForm();
  const [recaptchaVerifier, setRecaptchaVerifier] = useState<firebase.auth.RecaptchaVerifier>();

  useEffect(() => {
    if (verifyModalVisible) form.setFieldsValue({ mobile: user?.mobile });
  }, [verifyModalVisible]);

  const onFinish = async (values: any) => {
    try {
      setLoggingIn(true);
      await confirmationResult?.confirm(values['otp']);
      message.success('登入成功!');
      setVerified(true);
      setVerifyModalVisible(false);
    } finally {
      setLoggingIn(false);
    }
  };
  
  const sendOtp = async () => {
    form.validateFields(['mobile']).then(async (e) => {
      try {
        setSendingOtp(true);
        const mobile = `+852${e.mobile}`;
        auth.useDeviceLanguage();
        let recap = recaptchaVerifier;
        if (!recaptchaVerifier) {
          recap = new RecaptchaVerifier('send-otp', {
            size: 'invisible',
            callback: () => setOtpSent(true),
          });
          setRecaptchaVerifier(recap);
        }
        if (recap) {
          await auth
            .signInWithPhoneNumber(mobile, recap)
            .then((confirmationResult) => {
              setConfirmationResult(confirmationResult);
              message.success(`已傳送驗證碼到 ${Utils.formatPhone(mobile.replace('+852', ''))}`);
            })
            .catch((error) => {
              console.error(error);
              message.error('發生錯誤 請稍後再試');
            });
        }
      } finally {
        setSendingOtp(false);
      }
    });
  };

  return (
    <AppModal visible={verifyModalVisible} onCancel={() => setVerifyModalVisible(false)} title="確認驗證碼" subtitle="同時自動開設賬戶以享用完整服務" showLogo>
      <Form form={form} onFinish={onFinish} preserve={false}>
        <Row style={{ marginBottom: AppTheme.insetMd }} align="middle" justify="center" gutter={AppTheme.insetSm}>
          <Col flex="1">
            <PhoneInput name="mobile" label="手機號碼" required />
          </Col>
          <Col>
            <Form.Item noStyle shouldUpdate={(prevValues, curValues) => prevValues.mobile !== curValues.mobile}>
              {({ isFieldTouched, getFieldError }) => (
                <Button
                  id={'send-otp'}
                  style={{ padding: 0 }}
                  type="link"
                  disabled={!isFieldTouched('mobile') || !!getFieldError('mobile').filter((errors) => errors.length).length}
                  onClick={sendOtp}
                  loading={sendingOtp}
                >
                  {'發送驗證碼'}
                </Button>
              )}
            </Form.Item>
          </Col>
        </Row>
        <OtpInput name="otp" label="驗證碼" />
        <Row style={{ paddingTop: AppTheme.insetMd }} justify="end">
          <Col span={12}>
            <Form.Item shouldUpdate>
              {({ isFieldsTouched, getFieldsError }) => (
                <Button
                  size="large"
                  type="primary"
                  htmlType="submit"
                  block
                  disabled={!otpSent || !isFieldsTouched(true) || !!getFieldsError().filter(({ errors }) => errors.length).length}
                  loading={loggingIn}
                >
                  {'確認'}
                </Button>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </AppModal>
  );
};

export default VerifyModal;
