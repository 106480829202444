import { message } from 'antd';
import { PasswordInput } from 'components/form/AppForm';
import React from 'react';
import { InputModal } from './AppModal';

const UpdatePasswordModal = (props: { updatePassword: (password: string, newPassword: string) => Promise<void>; visible: boolean; onCancel: () => void }) => {
  const { updatePassword, visible, onCancel } = props;

  const onFinish = async (values: any) => {
    const { password, newPassword } = values;
    try {
      await updatePassword(password, newPassword);
      onCancel();
      message.success('成功更新密碼！');
    } catch (e) {
      if (e.code == 'auth/wrong-password') {
        message.warn('舊密碼錯誤！');
      } else {
        message.warn('系統錯誤 請稍後再試');
      }
      throw e;
    }
  };

  return (
    <InputModal
      visible={visible}
      onCancel={onCancel}
      title={'更改密碼'}
      formItem={
        <React.Fragment>
          <PasswordInput name="password" label="舊密碼" placeholder="輸入最少6位字元的密碼" requiredMessage="最少6位字元" />
          <PasswordInput name="newPassword" label="新密碼" placeholder="輸入最少6位字元的密碼" requiredMessage="最少6位字元" />
          <PasswordInput
            name="confirmPassword"
            label="重複新密碼"
            placeholder="再次輸入密碼"
            requiredMessage="最少6位字元"
            dependencies={['newPassword']}
            extraRules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('newPassword') === value) return Promise.resolve();
                  return Promise.reject('兩組密碼輸入必須相同');
                },
              }),
            ]}
          />
        </React.Fragment>
      }
      onSubmit={onFinish}
    />
  );
};

export default UpdatePasswordModal;
