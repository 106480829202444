/* eslint-disable react/display-name */
import { Affix, Button, Card, Row, Space, Typography } from 'antd';
import { ColumnsType, SorterResult } from 'antd/lib/table/interface';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { BooleanParam, NumberParam, StringParam, useQueryParam, withDefault } from 'use-query-params';
import AppConstant from '../../constants/constant';
import { PaymentStatus } from '../../constants/payment';
import { Pagination } from '../../models/pagination';
import { Payment } from '../../models/payment';
import { User } from '../../models/user';
import PaymentService from '../../services/paymentService';
import AppTheme from '../../theme/theme';
import Utils from '../../utils/utils';
import { BookmarkButton, LinkButton } from '../AppButton';
import { CloseIcon } from '../AppIcon';
import { AppTable, DateRangeFilter, SelectFilter } from './AppTable';

const PaymentTable = (props: { user?: User; showBookmark?: boolean; showUser?: boolean; userId?: number }) => {
  const { user, showBookmark, showUser, userId } = props;
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<Payment[]>();
  const [pagination, setPagination] = useState<Pagination>();
  const [page, setPage] = useQueryParam('page', withDefault(NumberParam, 1));
  const [pageSize, setPageSize] = useQueryParam('page_size', withDefault(NumberParam, 20));
  const [sortBy, setSortBy] = useQueryParam('sort_by', withDefault(StringParam, 'createdAt'));
  const [sort, setSort] = useQueryParam('sort', StringParam);
  const [date, setDate] = useQueryParam('date', StringParam);
  const [bookmarked, setBookmarked] = useQueryParam('bookmarked', BooleanParam);
  const [status, setStatus] = useQueryParam('status', withDefault(StringParam, 'ALL'));
  const [type, setType] = useQueryParam('type', withDefault(StringParam, 'ALL'));
  const [downloadingInvoice, setDownloadingInvoice] = useState(false);
  const [downloadingReceipt, setDownloadingReceipt] = useState(false);

  useEffect(() => {
    if (user) {
      getData();
    }
  }, [page, user, pageSize, sortBy, sort, status, type, date, bookmarked]);

  const getData = () => {
    setLoading(true);
    PaymentService.getAll({
      userId: userId,
      status: status == 'ALL' ? undefined : status,
      type: type == 'ALL' ? undefined : type,
      bookmarked: bookmarked == true ? true : undefined,
      createdAt: date as string | undefined,
      limit: pageSize,
      offset: (page - 1) * pageSize,
      orderBy: sortBy,
      sort: sort == 'ascend' ? 'ASC' : sort == 'descend' ? 'DESC' : undefined,
    })
      .then((e) => {
        setData(e?.payments);
        setPagination(e?.pagination);
      })
      .finally(() => setLoading(false));
  };

  const onSorterChange = (sorter: SorterResult<Payment> | SorterResult<Payment>[]) => {
    setSortBy((sorter as any).order ? (sorter as any).field : 'createdAt');
    setSort((sorter as any).order);
  };

  const onPageChange = (page: number, pageSize?: number) => {
    setPage(page);
    setPageSize(pageSize);
  };

  const toggleBookmark = (payment: Payment) => {
    try {
      PaymentService.bookmark({ paymentId: payment.id });
      const newPayment = data?.map((e) => {
        if (e.id == payment.id) {
          e.bookmarked = !payment.bookmarked;
        }
        return e;
      });
      setData(newPayment);
      console.log(payment);
    } catch (e) {
      console.log(e);
    }
  };

  const downloadInvoice = async (payment: Payment[]) => {
    setDownloadingInvoice(true);
    PaymentService.downloadInvoices({ ids: payment.map((e) => e.id) }).finally(() => setDownloadingInvoice(false));
  };

  const downloadReceipt = async (payment: Payment[]) => {
    setDownloadingReceipt(true);
    PaymentService.downloadReceipts({ ids: payment.map((e) => e.id) }).finally(() => setDownloadingReceipt(false));
  };

  const columns: ColumnsType<Payment> = [
    ...(showBookmark
      ? [
          {
            dataIndex: 'bookmarked',
            key: 'bookmarked',
            width: 70,
            render: (bookmarked: boolean, record: Payment) => <BookmarkButton bookmarked={bookmarked} onClick={() => toggleBookmark(record)} />,
          },
        ]
      : []),
    {
      title: '付款編號',
      dataIndex: 'id',
      key: 'id',
      width: 140,
      render: (id: number) => <LinkButton to={`/payment/${id}`}>{Utils.formatPaymentId(id)}</LinkButton>,
    },
    {
      title: '付款日期',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 180,
      sorter: () => 0,
      render: (createdAt: Date) => <Typography.Text>{moment(createdAt).format('DD/MM/YYYY')}</Typography.Text>,
    },
    {
      title: '付款模式',
      dataIndex: 'type',
      key: 'type',
      width: 150,
      render: (type: string) => <Typography.Text>{AppConstant.paymentTypeMap[type]}</Typography.Text>,
    },
    ...(showUser
      ? [
          {
            title: '寄件人',
            dataIndex: 'user',
            key: 'user',
            width: 180,
            ellipsis: { showTitle: false },
            render: (user: User) => <LinkButton to={`/customer/${user.id}`}>{user.name}</LinkButton>,
          },
        ]
      : []),
    {
      title: '金額',
      dataIndex: 'amount',
      key: 'amount',
      align: 'right',
      render: (amount: number) => <Typography.Text>{`$${amount}`}</Typography.Text>,
    },
    {
      title: '狀態',
      dataIndex: 'status',
      key: 'status',
      align: 'right',
      width: 150,
      render: (status: string) => <Typography.Text strong>{AppConstant.paymentStatusMap[status]}</Typography.Text>,
    },
  ];

  return (
    <AppTable
      data={data ?? []}
      columns={columns}
      loading={loading}
      filter={
        <Space>
          <SelectFilter
            label="狀態"
            width={120}
            defaultValue={status}
            onChange={setStatus}
            options={Object.keys(AppConstant.paymentStatusMap).map((e) => ({ label: AppConstant.paymentStatusMap[e], value: e }))}
          />
          <DateRangeFilter label="付款日期" onChange={(e) => setDate(e?.map((d) => d?.format('YYYY-MM-DD')).join(','))} />
          <SelectFilter
            label="付款模式"
            width={120}
            defaultValue={type}
            onChange={setType}
            options={Object.keys(AppConstant.paymentTypeMap).map((e) => ({ label: AppConstant.paymentTypeMap[e], value: e }))}
          />
        </Space>
      }
      extra={<Space size={AppTheme.insetXs}>{showBookmark && <BookmarkButton bookmarked={bookmarked} onClick={() => setBookmarked(!bookmarked)} filled />}</Space>}
      pagination={pagination}
      page={page}
      pageSize={pageSize}
      onSorterChange={onSorterChange}
      onPageChange={onPageChange}
      selectable
      extraAction={(selected: Payment[], clear) => (
        <Affix offsetBottom={40}>
          <Card style={{ display: selected.length > 0 ? 'block' : 'none' }} bordered={false}>
            <Row align="middle" justify="space-between">
              <Typography.Text>{`選取共 ${selected.length} 張貨單`}</Typography.Text>
              <Space>
                <Button type="primary" block onClick={() => downloadInvoice(selected)} loading={downloadingInvoice}>
                  {'下載發票'}
                </Button>
                <Button type="primary" block onClick={() => downloadReceipt(selected)} loading={downloadingReceipt} disabled={!!selected.filter((e) => e.status != PaymentStatus.APPROVED).length}>
                  {'下載收據'}
                </Button>
                <Button onClick={clear} icon={<CloseIcon />} />
              </Space>
            </Row>
          </Card>
        </Affix>
      )}
    />
  );
};
export default PaymentTable;
