import { AppModal, PasswordInput } from '@logistic/common';
import { Button, Form, Typography } from 'antd';
import { ClientContext } from 'App';
import React, { useContext } from 'react';

const ResetPasswordModal = () => {
  const { resetPasswordModalVisible, setResetPasswordModalVisible } = useContext(ClientContext);

  const onFinish = (values: any) => {
    console.log(values);
  };

  return (
    <AppModal visible={resetPasswordModalVisible} onCancel={() => setResetPasswordModalVisible(false)}>
      <Typography.Title level={4}>{'重設密碼'}</Typography.Title>
      <Form onFinish={onFinish} layout="horizontal" preserve={false}>
        <PasswordInput name="password" label="新密碼" placeholder="輸入最少6位字元的密碼" />
        <PasswordInput
          name="confirmPassword"
          label="重複新密碼"
          placeholder="再次輸入新密碼"
          dependencies={['password']}
          extraRules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) return Promise.resolve();
                return Promise.reject('兩組密碼輸入必須相同');
              },
            }),
          ]}
        />
        <Form.Item style={{ textAlign: 'center' }}>
          <Button style={{ height: 48, width: 240 }} type="primary" htmlType="submit" block>
            {'重設'}
          </Button>
        </Form.Item>
      </Form>
    </AppModal>
  );
};

export default ResetPasswordModal;
