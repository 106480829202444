import { AccountIcon, AppTheme, SearchBar, Utils } from '@logistic/common';
import { Col, Form, Layout, Menu, Row } from 'antd';
import { ClientContext } from 'App';
import { ReactComponent as AppLogo } from 'assets/img/logo.svg';
import React, { useContext } from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';

const AppHeader = () => {
  const location = useLocation();
  const pathSnippets = location.pathname.split('/').filter((i) => i);
  const [form] = Form.useForm();
  const { setLoginModalVisible } = useContext(ClientContext);
  const history = useHistory();

  const search = (id: string) => {
    Utils.search(history, id, () => form.resetFields());
  };

  return (
    <Layout.Header className="app-header">
      <Row align={'middle'} justify={'space-between'}>
        <AppLogo />
        <Row align={'middle'}>
          <Menu mode="horizontal" selectedKeys={[`/${pathSnippets[0] ?? ''}`]}>
            <Menu.Item key="/">
              <NavLink to="/">{'主頁'}</NavLink>
            </Menu.Item>
            <Menu.Item key="/create_order">
              <NavLink to="/create_order">{'落單'}</NavLink>
            </Menu.Item>
            {Utils.isLoggedIn() && (
              <Menu.Item key="/order">
                <NavLink to="/order">{'貨單'}</NavLink>
              </Menu.Item>
            )}
            {Utils.isLoggedIn() && (
              <Menu.Item key="/payment">
                <NavLink to="/payment">{'付款紀錄'}</NavLink>
              </Menu.Item>
            )}
            <Menu.Item key="/pricing">
              <NavLink to="/pricing">{'收費'}</NavLink>
            </Menu.Item>
            <Menu.Item key="/faq">
              <NavLink to="/faq">{'常見問題'}</NavLink>
            </Menu.Item>
          </Menu>
          <Menu mode="horizontal" selectable={false}>
            {Utils.isLoggedIn() ? (
              <Menu.Item>
                <NavLink to="/profile">
                  <Row align="middle">
                    <AccountIcon style={{ fontSize: 24, display: 'block' }} />
                    <Col
                      style={{
                        maxWidth: 64,
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                      }}
                    >
                      {localStorage.getItem('username')}
                    </Col>
                  </Row>
                </NavLink>
              </Menu.Item>
            ) : (
              <Menu.Item onClick={() => setLoginModalVisible(true)}>{'登入/登記'}</Menu.Item>
            )}
          </Menu>
          <SearchBar form={form} onFinish={search} style={{ marginLeft: AppTheme.insetMd, width: 400 }} placeholder="貨單搜尋" size="small" />
        </Row>
      </Row>
    </Layout.Header>
  );
};

export default AppHeader;
