/* eslint-disable react/display-name */
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { Row, Space, Typography } from 'antd';
import { ColumnsType, SorterResult } from 'antd/lib/table/interface';
import moment from 'antd/node_modules/moment';
import AppConstant from 'constants/constant';
import { InventoryRecordType } from 'constants/invnetory';
import { InventoryRecord } from 'models/inventory';
import React, { useEffect, useState } from 'react';
import { NumberParam, StringParam, useQueryParam, withDefault } from 'use-query-params';
import { Pagination } from '../../models/pagination';
import InventoryService from '../../services/inventoryService';
import AppTheme from '../../theme/theme';
import { AppTable } from './AppTable';

const InventoryRecordTable = (props: { id: number | string; total: number }) => {
  const { id, total } = props;
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<InventoryRecord[]>();
  const [pagination, setPagination] = useState<Pagination>();
  const [page, setPage] = useQueryParam('page', withDefault(NumberParam, 1));
  const [pageSize, setPageSize] = useQueryParam('page_size', withDefault(NumberParam, 20));
  const [sortBy, setSortBy] = useQueryParam('sort_by', withDefault(StringParam, 'createdAt'));
  const [sort, setSort] = useQueryParam('sort', StringParam);

  useEffect(() => {
    getData();
  }, [page, pageSize, sortBy, sort]);

  const getData = () => {
    setLoading(true);
    InventoryService.getInventoryRecords({
      inventoryId: id,
      limit: pageSize,
      offset: (page - 1) * pageSize,
      orderBy: sortBy,
      sort: sort == 'ascend' ? 'ASC' : sort == 'descend' ? 'DESC' : undefined,
    })
      .then((e) => {
        setData(e.inventoryRecords);
        setPagination(e.pagination);
      })
      .finally(() => setLoading(false));
  };

  const onSorterChange = (sorter: SorterResult<InventoryRecord> | SorterResult<InventoryRecord>[]) => {
    setSortBy((sorter as any).order ? (sorter as any).field : 'createdAt');
    setSort((sorter as any).order);
  };

  const onPageChange = (page: number, pageSize?: number) => {
    setPage(page);
    setPageSize(pageSize);
  };

  const columns: ColumnsType<InventoryRecord> = [
    {
      title: '日期',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 150,
      render: (createdAt: Date) => <Typography.Text>{moment(createdAt).format('DD/MM/YYYY')}</Typography.Text>,
    },
    {
      title: '出入貨',
      dataIndex: 'type',
      key: 'type',
      align: 'right',
      ellipsis: { showTitle: false },
      render: (type: string) => (
        <Typography.Text style={{ color: AppConstant.inventoryTypeColorMap[type] }} strong>
          <Space size={AppTheme.insetXsss}>
            {type == InventoryRecordType.IN ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
            {AppConstant.inventoryTypeMap[type]}
          </Space>
        </Typography.Text>
      ),
    },
    {
      title: '數量',
      dataIndex: 'quantity',
      key: 'quantity',
      align: 'right',
      width: 120,
      render: (quantity: number) => <Typography.Text>{quantity?.toLocaleString()}</Typography.Text>,
    },
    {
      title: '結餘',
      dataIndex: 'total',
      key: 'total',
      align: 'right',
      width: 120,
      render: (_, __, i) =>
        i == 0 ? (
          <Row justify="end">
            <Typography.Text style={{ backgroundColor: AppTheme.secondayColor }} strong>
              {total?.toLocaleString()}
            </Typography.Text>
          </Row>
        ) : null,
    },
  ];

  return <AppTable data={data ?? []} columns={columns} loading={loading} pagination={pagination} page={page} pageSize={pageSize} onSorterChange={onSorterChange} onPageChange={onPageChange} />;
};
export default InventoryRecordTable;
