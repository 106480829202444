import { message } from 'antd';
import { EmailInput } from 'components/form/AppForm';
import { AppContext } from 'context/context';
import React, { useContext } from 'react';
import { InputModal } from './AppModal';

const ForgetPasswordModal = (props: { forgotPassword: (email: string) => Promise<void> }) => {
  const { forgotPassword } = props;
  const { forgetPasswordModalVisible, setForgetPasswordModalVisible } = useContext(AppContext);

  const onFinish = async (values: any) => {
    try {
      await forgotPassword(values.email);
      message.success('已發送重設密碼電郵!');
      setForgetPasswordModalVisible(false);
    } catch (e) {
      if (e.code == 'auth/user-not-found') message.warn('電郵不存在!');
      throw e;
    }
  };

  return (
    <InputModal
      title="唔記得咗密碼"
      subtitle="一條連結會寄到你既電郵地址, 點擊重設密碼"
      visible={forgetPasswordModalVisible}
      onCancel={() => setForgetPasswordModalVisible(false)}
      formItem={<EmailInput name="email" label="電郵" required />}
      onSubmit={onFinish}
    />
  );
};

export default ForgetPasswordModal;
