import { message } from 'antd';
import axios from 'axios';
import firebase from 'firebase/app';
import AppConfig from '../config/config';
import { Inventory, InventoryRecord } from '../models/inventory';
import { Pagination } from '../models/pagination';

const api = axios.create({
  baseURL: AppConfig.apiUrl,
  timeout: AppConfig.timeout,
});

export default class InventoryService {
  static getInventory = async function (id: number | string): Promise<Inventory> {
    try {
      const response = await api.get('/get_inventory', { headers: { authorization: `Bearer ${await firebase.auth().currentUser?.getIdToken()}` }, params: { id } });
      return response.data;
    } catch (e) {
      if (e?.response?.status == 400) message.warn('請稍後再試');
      throw e;
    }
  };

  static getInventories = async function (params: { userId?: number; limit: number; offset: number; orderBy: string; sort?: string }): Promise<{ pagination: Pagination; inventories: Inventory[] }> {
    try {
      const response = await api.get('/get_inventories', { headers: { authorization: `Bearer ${await firebase.auth().currentUser?.getIdToken()}` }, params });
      return response.data;
    } catch (e) {
      if (e?.response?.status == 400) message.warn('請稍後再試');
      throw e;
    }
  };

  static getInventoryRecords = async function (params: {
    inventoryId: number | string;
    limit: number;
    offset: number;
    orderBy: string;
    sort?: string;
  }): Promise<{ pagination: Pagination; inventoryRecords: InventoryRecord[] }> {
    try {
      const response = await api.get('/get_inventory_records', { headers: { authorization: `Bearer ${await firebase.auth().currentUser?.getIdToken()}` }, params });
      return response.data;
    } catch (e) {
      if (e?.response?.status == 400) message.warn('請稍後再試');
      throw e;
    }
  };
}
