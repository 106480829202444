import { Button, Typography } from 'antd';
import React, { CSSProperties, ReactNode, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PaymentStatus } from '../constants/payment';
import { Order } from '../models/order';
import { Payment } from '../models/payment';
import { Admin, User } from '../models/user';
import OrderService from '../services/orderService';
import PaymentService from '../services/paymentService';
import AppTheme from '../theme/theme';
import { BlockIcon, BookmarkFilledIcon, BookmarkIcon } from './AppIcon';
import { UpdateProfileModal } from './modal/AppModal';

export const WaybillButton = (props: { order: Order }) => {
  const { order } = props;
  const [loading, setLoading] = useState(false);
  return (
    <Button
      type="primary"
      size="large"
      block
      loading={loading}
      onClick={() => {
        setLoading(true);
        OrderService.downloadWaybill(order.id).finally(() => setLoading(false));
      }}
    >
      {'貨單'}
    </Button>
  );
};

export const InvoiceButton = (props: { payment: Payment; style?: CSSProperties }) => {
  const { payment, style } = props;
  const [loading, setLoading] = useState(false);
  return (
    <Button
      type="primary"
      style={style}
      size="small"
      loading={loading}
      onClick={() => {
        setLoading(true);
        PaymentService.downloadInvoice(payment.id).finally(() => setLoading(false));
      }}
    >
      {'發票'}
    </Button>
  );
};

export const ReceiptButton = (props: { payment: Payment; style?: CSSProperties }) => {
  const { payment, style } = props;
  const [loading, setLoading] = useState(false);
  return (
    <Button
      type="primary"
      style={style}
      size="small"
      loading={loading}
      disabled={payment.status != PaymentStatus.APPROVED}
      onClick={() => {
        setLoading(true);
        PaymentService.downloadReceipt(payment.id).finally(() => setLoading(false));
      }}
    >
      {'收據'}
    </Button>
  );
};

export const BookmarkButton = (props: { bookmarked?: boolean | null; onClick: () => void; filled?: boolean }) => {
  const { bookmarked, onClick, filled } = props;
  return (
    <Button
      style={{
        color: bookmarked ? AppTheme.bookmarkColor : AppTheme.disabledColor,
        backgroundColor: filled ? AppTheme.inputBackgroundColor : undefined,
        paddingLeft: filled ? AppTheme.insetMd : undefined,
        paddingRight: filled ? AppTheme.insetMd : undefined,
        width: filled ? 88 : undefined,
      }}
      size="large"
      type="link"
      icon={bookmarked ? <BookmarkFilledIcon style={{ fontSize: 24 }} /> : <BookmarkIcon style={{ fontSize: 24 }} />}
      onClick={onClick}
    />
  );
};

export const BlockButton = (props: { blocked?: boolean | null; onClick: () => void; filled?: boolean }) => {
  const { blocked, onClick, filled } = props;
  return (
    <Button
      style={{
        color: blocked ? AppTheme.blockColor : AppTheme.disabledColor,
        backgroundColor: filled ? AppTheme.inputBackgroundColor : undefined,
        paddingLeft: filled ? AppTheme.insetMd : undefined,
        paddingRight: filled ? AppTheme.insetMd : undefined,
        width: filled ? 88 : undefined,
      }}
      size="large"
      type="link"
      icon={<BlockIcon style={{ fontSize: 24 }} />}
      onClick={onClick}
    />
  );
};

export const EditProfileButton = (props: { user?: User; admin: Admin; onSuccess: () => void }) => {
  const { user, admin, onSuccess } = props;
  const [editing, setEditing] = useState(false);
  return (
    <React.Fragment>
      <Typography.Link onClick={() => setEditing(true)} underline>
        {admin.name}
      </Typography.Link>
      <UpdateProfileModal user={user} admin={admin} visible={editing} onCancel={() => setEditing(false)} onSuccess={onSuccess} />
    </React.Fragment>
  );
};

export const LinkButton = (props: { children: ReactNode; to: string }) => {
  const { children, to } = props;
  const history = useHistory();
  return (
    <Typography.Link underline onClick={() => history.push(to)}>
      {children}
    </Typography.Link>
  );
};
