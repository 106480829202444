export enum PaymentStatus {
  PROCESSING = 'PROCESSING',
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED',
}

export enum PaymentType {
  SINGLE = 'SINGLE',
  GROUP = 'GROUP',
  REPLENISH = 'REPLENISH',
}
