import { CheckCircleFilled, ClockCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { Button, Checkbox, Col, Form, List, message, Row, Space, Typography } from 'antd';
import hexToRgba from 'hex-to-rgba';
import moment from 'moment';
import React, { ReactNode, useContext, useState } from 'react';
import { NavLink } from 'react-router-dom';
import AppConfig from '../config/config';
import AppConstant from '../constants/constant';
import { OrderStatus } from '../constants/orderStatus';
import { PaymentStatus } from '../constants/payment';
import { AppContext } from '../context/context';
import { Goods, Order } from '../models/order';
import OrderService from '../services/orderService';
import AppTheme from '../theme/theme';
import Utils from '../utils/utils';
import { InvoiceButton, ReceiptButton, WaybillButton } from './AppButton';
import AppCard from './AppCard';
import { CheckBoxIcon } from './AppIcon';
import { InfoRow } from './AppLayout';
import {
  DeliverAddressInput,
  DeliverChannelInput,
  IntegerInput,
  LineInput,
  PickupAddressInput,
  PickupChannelInput,
  RadioInputRow,
  ReceiverMobileInput,
  ReceiverNameInput,
  SenderMobileInput,
  SenderNameInput,
} from './form/AppForm';
import { ImageModal, InputModal } from './modal/AppModal';

export const SenderCard = (props: { order: Order; onEdited?: (order: Order) => void }) => {
  const { order, onEdited } = props;
  const [editing, setEditing] = useState(false);

  const onSubmit = async (values: any) => {
    const data = await OrderService.updateOrder({
      id: order.id,
      ...values,
    });
    message.success('成功更新資料!');
    if (onEdited) onEdited(data);
  };

  return (
    <AppCard
      title="寄件"
      extra={
        onEdited && (
          <React.Fragment>
            <Button type="primary" size="small" onClick={() => setEditing(true)}>
              {'編輯'}
            </Button>
            <InputModal
              visible={editing}
              onCancel={() => setEditing(false)}
              title="編輯寄件"
              initialValues={order}
              width={AppTheme.modalWidthLg}
              formItem={
                <Space style={{ marginBottom: AppTheme.insetMd }} direction="vertical" size={AppTheme.insetMd}>
                  <SenderNameInput />
                  <SenderMobileInput />
                  <PickupChannelInput />
                  <PickupAddressInput />
                </Space>
              }
              onSubmit={onSubmit}
            />
          </React.Fragment>
        )
      }
    >
      <Space direction="vertical" size={AppTheme.insetXss}>
        <InfoRow label={'寄件人'} value={order.senderName} />
        <InfoRow label={'電話'} value={Utils.formatPhone(order.senderMobile)} />
        <InfoRow label={'收件方式'} value={AppConstant.pickupChannelMap[order.pickupChannel]} />
        {order.pickupChannel == 'PICKUP' && (
          <React.Fragment>
            <InfoRow label={'收貨地址'} value={order.pickupAddress} />
            <InfoRow label={'地區'} value={order.pickupDistrict} />
          </React.Fragment>
        )}
        {order.pickupChannel == 'MTR' && <InfoRow label={'地鐵站'} value={order.pickupMtrStation} />}
      </Space>
    </AppCard>
  );
};

export const ReceiverCard = (props: { order: Order; onEdited?: (order: Order) => void }) => {
  const { order, onEdited } = props;
  const [editing, setEditing] = useState(false);

  const onSubmit = async (values: any) => {
    const data = await OrderService.updateOrder({
      id: order.id,
      ...values,
    });
    message.success('成功更新資料!');
    if (onEdited) onEdited(data);
  };

  return (
    <AppCard
      title="收件"
      extra={
        onEdited && (
          <React.Fragment>
            <Button type="primary" size="small" onClick={() => setEditing(true)}>
              {'編輯'}
            </Button>
            <InputModal
              visible={editing}
              onCancel={() => setEditing(false)}
              title="編輯收件"
              initialValues={order}
              width={AppTheme.modalWidthLg}
              formItem={
                <Space style={{ marginBottom: AppTheme.insetMd }} direction="vertical" size={AppTheme.insetMd}>
                  <ReceiverNameInput />
                  <ReceiverMobileInput />
                  <DeliverChannelInput />
                  <DeliverAddressInput />
                </Space>
              }
              onSubmit={onSubmit}
            />
          </React.Fragment>
        )
      }
    >
      <Space direction="vertical" size={AppTheme.insetXss}>
        <InfoRow label={'收件人'} value={order.receiverName} />
        <InfoRow label={'電話'} value={Utils.formatPhone(order.receiverMobile)} />
        <InfoRow label={'派件方式'} value={AppConstant.deliverChannelMap[order.deliverChannel]} />
        {order.deliverChannel == 'DELIVER' && (
          <React.Fragment>
            <InfoRow label={'收貨地址'} value={order.deliverAddress} />
            <InfoRow label={'地區'} value={order.deliverDistrict} />
          </React.Fragment>
        )}
        {order.deliverChannel == 'MTR' && <InfoRow label={'地鐵站'} value={order.deliverMtrStation} />}
      </Space>
    </AppCard>
  );
};

export const GoodsCard = (props: { order: Order; onEdited?: (order: Order) => void }) => {
  const { order, onEdited } = props;
  const [editing, setEditing] = useState(false);

  const onSubmit = async (values: any) => {
    const data = await OrderService.updateOrder({
      id: order.id,
      goods: [
        {
          ...values,
          weight: Number(values.weight),
        },
      ],
    });
    message.success('成功更新資料!');
    if (onEdited) onEdited(data);
  };

  return (
    <AppCard
      title="貨物備註"
      extra={
        onEdited && (
          <React.Fragment>
            <Button type="primary" size="small" onClick={() => setEditing(true)}>
              {'編輯'}
            </Button>
            <InputModal
              width={AppTheme.modalWidthLg}
              visible={editing}
              onCancel={() => setEditing(false)}
              title={'編輯貨物'}
              initialValues={order.goods[0]}
              formItem={
                <React.Fragment>
                  <LineInput name="description" label="貨品描述" placeholder="輸入郵寄物品" required />
                  <IntegerInput name="weight" label="重量" suffix={<Typography.Text type="secondary">{'kg'}</Typography.Text>} required />
                  <LineInput name="remark" label="備註" placeholder="可填寫特別事項" />
                  <Space direction="vertical" size={AppTheme.insetMd}>
                    <Typography.Text>{'選擇注意事項'}</Typography.Text>
                    <Form.Item style={{ marginBottom: -AppTheme.insetSm }} name="additional">
                      <Checkbox.Group
                        options={Object.keys(AppConstant.goodsAdditionalMap).map((e) => ({
                          label: AppConstant.goodsAdditionalMap[e],
                          value: e,
                        }))}
                      />
                    </Form.Item>
                    <RadioInputRow
                      style={{ marginBottom: -AppTheme.insetXss }}
                      name="package"
                      initialValue={'BAG'}
                      label="以袋/箱運送"
                      options={[
                        { value: 'BAG', label: '袋' },
                        { value: 'BOX', label: '箱' },
                      ]}
                    />
                  </Space>
                </React.Fragment>
              }
              onSubmit={onSubmit}
            />
          </React.Fragment>
        )
      }
    >
      <Space direction="vertical" size={AppTheme.insetXss}>
        {order.goods.map((e, i) => (
          <_GoodsRow key={i} label={`貨物${AppConfig.multiGoodsEnabled ? i + 1 : ''}`} goods={e} />
        ))}
      </Space>
    </AppCard>
  );
};

export const OrderCard = (props: { order: Order; onCancel?: () => void }) => {
  const { config } = useContext(AppContext);
  const { order, onCancel } = props;

  return (
    <AppCard title="貨單資料">
      <Space direction="vertical" size={AppTheme.insetMd}>
        <Space direction="vertical" size={AppTheme.insetXss}>
          <InfoRow label={'落單日期'} value={moment(order.createdAt).format('DD/MM/YYYY')} />
          <InfoRow label={'送貨日期'} value={config.orderDeliveryRemark} />
        </Space>
        {Utils.orderPayable(order) && (
          <Button type="primary" size="large" block onClick={onCancel}>
            {'取消貨單'}
          </Button>
        )}
      </Space>
    </AppCard>
  );
};

export const OrderPaymentCard = (props: { order: Order; extra?: ReactNode }) => {
  const { config } = useContext(AppContext);
  const { order, extra } = props;
  const [imageModalVisible, setImageModalVisible] = useState(false);
  const payments = order.payments?.filter((e) => !e.replenish) ?? [];
  const payment = payments.length ? payments[0] : undefined;
  return (
    <AppCard
      title="付款記錄"
      extra={
        payment && (
          <Space size={AppTheme.insetMd}>
            <InvoiceButton payment={payment} />
            <ReceiptButton payment={payment} />
          </Space>
        )
      }
    >
      <Space direction="vertical" size={AppTheme.insetMd}>
        <InfoRow label={'付款方式'} value={Utils.getPaymentChannelByKey(config.paymentChannel, order.paymentChannel)?.name ?? order.paymentChannel} />
        {!!payments.length && (
          <List
            style={{ marginLeft: -AppTheme.insetMd, marginRight: -AppTheme.insetMd }}
            itemLayout="horizontal"
            dataSource={payments}
            header={
              <Row style={{ paddingLeft: AppTheme.insetMd, paddingRight: AppTheme.insetMd }} justify="space-between">
                <Typography.Text type="secondary">{'付款記錄'}</Typography.Text>
                <Typography.Link onClick={() => setImageModalVisible(true)} underline>
                  {`入數證明 (${payments.length})`}
                </Typography.Link>
                <ImageModal visible={imageModalVisible} onCancel={() => setImageModalVisible(false)} image={payments.map((e) => e.attachment)} />
              </Row>
            }
            renderItem={(item, i) => (
              <Row style={{ backgroundColor: i % 2 == 1 ? AppTheme.inputBackgroundColor : undefined, padding: `${AppTheme.insetXs}px ${AppTheme.insetMd}px` }} align="middle" justify="space-between">
                <Typography.Text>{moment(item.createdAt).format('DD/MM/YYYY')}</Typography.Text>
                <Space>
                  <NavLink to={`/payment/${item.id}`}>
                    <Typography.Text underline>{Utils.formatPaymentId(item.id)}</Typography.Text>
                  </NavLink>
                  {item.status == PaymentStatus.APPROVED && <CheckCircleFilled style={{ color: AppConstant.paymentStatusColorMap[item.status] }} />}
                  {item.status == PaymentStatus.REJECTED && <CloseCircleFilled style={{ color: AppConstant.paymentStatusColorMap[item.status] }} />}
                  {item.status == PaymentStatus.PROCESSING && <ClockCircleFilled style={{ color: AppConstant.paymentStatusColorMap[item.status] }} />}
                </Space>
              </Row>
            )}
          />
        )}
        {extra}
      </Space>
    </AppCard>
  );
};

export const OrderReplenishCard = (props: { order: Order; extra?: ReactNode }) => {
  const { config } = useContext(AppContext);
  const { order, extra } = props;
  const [imageModalVisible, setImageModalVisible] = useState(false);
  const payments = order.payments?.filter((e) => e.replenish) ?? [];
  const payment = payments.length ? payments[0] : undefined;
  return (
    <AppCard
      title="補錢記錄"
      extra={
        payment && (
          <Space size={AppTheme.insetMd}>
            <InvoiceButton payment={payment} />
            <ReceiptButton payment={payment} />
          </Space>
        )
      }
    >
      <Space direction="vertical" size={AppTheme.insetMd}>
        <InfoRow label={'付款方式'} value={Utils.getPaymentChannelByKey(config.paymentChannel, order.paymentChannel)?.name ?? order.paymentChannel} />
        {!!payments.length && (
          <List
            style={{ marginLeft: -AppTheme.insetMd, marginRight: -AppTheme.insetMd }}
            itemLayout="horizontal"
            dataSource={payments}
            header={
              <Row style={{ paddingLeft: AppTheme.insetMd, paddingRight: AppTheme.insetMd }} justify="space-between">
                <Typography.Text type="secondary">{'付款記錄'}</Typography.Text>
                <Typography.Link onClick={() => setImageModalVisible(true)} underline>
                  {`入數證明 (${payments.length})`}
                </Typography.Link>
                <ImageModal visible={imageModalVisible} onCancel={() => setImageModalVisible(false)} image={payments.map((e) => e.attachment)} />
              </Row>
            }
            renderItem={(item, i) => (
              <Row style={{ backgroundColor: i % 2 == 1 ? AppTheme.inputBackgroundColor : undefined, padding: `${AppTheme.insetXs}px ${AppTheme.insetMd}px` }} align="middle" justify="space-between">
                <Typography.Text>{moment(item.createdAt).format('DD/MM/YYYY')}</Typography.Text>
                <Space>
                  <NavLink to={`/payment/${item.id}`}>
                    <Typography.Text underline>{Utils.formatPaymentId(item.id)}</Typography.Text>
                  </NavLink>
                  {item.status == PaymentStatus.APPROVED && <CheckCircleFilled style={{ color: AppConstant.paymentStatusColorMap[item.status] }} />}
                  {item.status == PaymentStatus.REJECTED && <CloseCircleFilled style={{ color: AppConstant.paymentStatusColorMap[item.status] }} />}
                  {item.status == PaymentStatus.PROCESSING && <ClockCircleFilled style={{ color: AppConstant.paymentStatusColorMap[item.status] }} />}
                </Space>
              </Row>
            )}
          />
        )}
        {extra}
      </Space>
    </AppCard>
  );
};

export const ChargeCard = (props: { order: Order; bordered?: boolean }) => {
  const { order, bordered } = props;
  return (
    <AppCard
      title="付費總額"
      actions={
        <Row align="middle" justify="space-between">
          <Typography.Title level={4}>{'總金額'}</Typography.Title>
          <Typography.Title level={4}>{`$${order.totalCharge ?? 0}`}</Typography.Title>
        </Row>
      }
      bordered={bordered}
    >
      <List
        header={
          <Row style={{ marginBottom: AppTheme.insetXs, borderBottom: `1px solid ${AppTheme.borderColor}` }} justify="space-between">
            <Typography.Text type="secondary">{'貨物'}</Typography.Text>
            <Typography.Text type="secondary">{'價錢'}</Typography.Text>
          </Row>
        }
        dataSource={order.goods}
        renderItem={(item: Goods) => {
          const { description, weight } = item;
          return (
            <List.Item.Meta
              description={
                <Space direction="vertical" size={AppTheme.insetXsss}>
                  <Typography.Text>{description}</Typography.Text>
                  <Typography.Text>{`${weight}kg`}</Typography.Text>
                </Space>
              }
            />
          );
        }}
        footer={
          <Space direction="vertical" size={AppTheme.insetXsss}>
            <InfoRow label="運費" value={`$${order.itemCharge ?? 0}`} />
            {Number(order.pickupAreaCharge) > 0 && <InfoRow label="偏遠地區付加費(收件)" value={`$${order.pickupAreaCharge}`} />}
            {Number(order.pickupFloorCharge) > 0 && <InfoRow label="無電梯設備的樓宇付加費(收件)" value={`$${order.pickupFloorCharge}`} />}
            {Number(order.deliverAreaCharge) > 0 && <InfoRow label="偏遠地區付加費(派件)" value={`$${order.deliverAreaCharge}`} />}
            {Number(order.deliverFloorCharge) > 0 && <InfoRow label="無電梯設備的樓宇付加費(派件)" value={`$${order.pickupFloorCharge}`} />}
            {Number(order.extraCharge) > 0 && <InfoRow label="額外收費" value={`$${order.extraCharge}`} />}
          </Space>
        }
      />
    </AppCard>
  );
};

export const PickupSignatureCard = (props: { order: Order }) => {
  const { order } = props;
  return (
    <AppCard title="客戶簽收 (收件)">
      <img src={order.pickupSignature} />
    </AppCard>
  );
};

export const PickupDriverCard = (props: { order: Order }) => {
  const { order } = props;
  const { pickupDriver } = order;
  return (
    <AppCard title="收件司機">
      <Space direction="vertical" size={AppTheme.insetXs}>
        <InfoRow label="名稱" value={pickupDriver?.name} />
        <InfoRow label="編號" value={Utils.formatDriverId(pickupDriver?.id)} />
        <InfoRow label="電話" value={Utils.formatPhone(pickupDriver?.mobile)} />
        <InfoRow label="車牌" value={pickupDriver?.license} />
        <InfoRow label="接單狀態" value={'-'} />
      </Space>
    </AppCard>
  );
};

export const DeliverSignatureCard = (props: { order: Order }) => {
  const { order } = props;
  return (
    <AppCard title="客戶簽收 (派件)">
      <img src={order.deliverSignature} />
    </AppCard>
  );
};

export const DeliverDriverCard = (props: { order: Order }) => {
  const { order } = props;
  const { deliverDriver } = order;
  return (
    <AppCard title="派件司機">
      <Space direction="vertical" size={AppTheme.insetXs}>
        <InfoRow label="名稱" value={deliverDriver?.name} />
        <InfoRow label="編號" value={Utils.formatDriverId(deliverDriver?.id)} />
        <InfoRow label="電話" value={Utils.formatPhone(deliverDriver?.mobile)} />
        <InfoRow label="車牌" value={deliverDriver?.license} />
        <InfoRow label="接單狀態" value={'-'} />
      </Space>
    </AppCard>
  );
};

export const GeneralRemarkCard = (props: { bordered?: boolean }) => {
  const { config } = useContext(AppContext);
  const { bordered } = props;
  return (
    <AppCard title="注意事項" bordered={bordered}>
      <Typography.Text>{config.generalRemark}</Typography.Text>
    </AppCard>
  );
};

export const StatusHistoryCard = (props: { order: Order; extra?: ReactNode; filter?: boolean }) => {
  const { order, extra, filter = false } = props;
  return (
    <AppCard title="貨單狀態" extra={extra}>
      {order.orderHistories
        .filter((e) => !filter || ![OrderStatus.ASSIGNING_DELIVER_DRIVER, OrderStatus.ASSIGNING_PICKUP_DRIVER].includes(e.status))
        .map((e, i) => {
          const isFirst = i == 0;
          const statusColor = AppConstant.orderStatusColorMap[e.status];
          return (
            <Space key={i} direction="vertical" size={AppTheme.insetXs}>
              <Space size={AppTheme.insetMd}>
                <Row style={{ width: 16, height: 16 }} align="middle" justify="center">
                  <div style={{ height: isFirst ? 16 : 8, width: isFirst ? 16 : 8, borderRadius: '50%', backgroundColor: isFirst ? statusColor : AppTheme.disabledColor }} />
                </Row>
                <Typography.Text type="secondary">{moment(e.createdAt).format('DD/MM/YYYY, hh:mm')}</Typography.Text>
              </Space>
              <div style={{ marginLeft: 8, paddingLeft: AppTheme.insetMd, borderLeft: `1px solid ${AppTheme.disabledColor}` }}>
                <Space
                  style={{
                    backgroundColor: isFirst ? hexToRgba(statusColor, 0.2) : undefined,
                    paddingTop: isFirst ? AppTheme.insetXs : undefined,
                    paddingBottom: isFirst ? AppTheme.insetXs : undefined,
                    paddingLeft: AppTheme.insetMd,
                    borderRadius: AppTheme.borderRadius,
                  }}
                  direction="vertical"
                  size={AppTheme.insetXsss}
                >
                  <Typography.Title level={5}>{AppConstant.orderStatusMap[e.status]}</Typography.Title>
                  {AppConstant.orderStatusDescriptionMap[e.status] && <Typography.Text>{AppConstant.orderStatusDescriptionMap[e.status]}</Typography.Text>}
                  {e.status == OrderStatus.PAYMENT_REPLENISH && e.payload && <Typography.Text>{`${e.payload.replenishReason}, 請補上餘額 $${e.payload.replenishCharge}`}</Typography.Text>}
                  {e.status == OrderStatus.PAYMENT_REJECTED && e.payload && <Typography.Text>{`拒絕入數, 原因 [${e.payload.reason}]。`}</Typography.Text>}
                </Space>
              </div>
            </Space>
          );
        })}
    </AppCard>
  );
};

export const WayBillCard = (props: { order: Order }) => {
  const { order } = props;
  return (
    <AppCard title="下載資訊">
      <WaybillButton order={order} />
    </AppCard>
  );
};

export const OrderStatusBox = (props: { status: string; fontSize?: number }) => {
  const { status, fontSize } = props;
  return (
    <Row align="middle" justify="end">
      <Col style={{ backgroundColor: AppConstant.orderStatusColorMap[status] }}>
        <Typography.Text style={{ fontSize: fontSize }} strong>
          {AppConstant.orderStatusMap[status]}
        </Typography.Text>
      </Col>
    </Row>
  );
};

export const OrderTitle = (props: { order: Order; extra?: ReactNode }) => {
  const { order, extra } = props;
  return (
    <Row align="middle" justify="space-between">
      <Typography.Title level={4}>{`貨單編號: ${Utils.formatOrderId(order.id)}`}</Typography.Title>
      {extra}
    </Row>
  );
};

const _GoodsRow = (props: { label: string; goods: Goods }) => {
  const { fragile, thisWayUp } = props.goods;
  return (
    <Row>
      <Col flex="90px">
        <Typography.Text>{props.label}</Typography.Text>
      </Col>
      <Col flex="1">
        <Space direction="vertical" size={AppTheme.insetXss}>
          <Typography.Text>{props.goods.description}</Typography.Text>
          {fragile && (
            <Space size={AppTheme.insetXss}>
              <CheckBoxIcon style={{ fontSize: 20 }} />
              <Typography.Text>{AppConstant.goodsAdditionalMap['FRAGILE']}</Typography.Text>
            </Space>
          )}
          {thisWayUp && (
            <Space size={AppTheme.insetXss}>
              <CheckBoxIcon style={{ fontSize: 20 }} />
              <Typography.Text>{AppConstant.goodsAdditionalMap['THIS_WAY_UP']}</Typography.Text>
            </Space>
          )}
          <Space size={AppTheme.insetXss}>
            <CheckBoxIcon style={{ fontSize: 20 }} />
            <Typography.Text>{props.goods.package == 'BAG' ? '以袋運送' : '以箱運送'}</Typography.Text>
          </Space>
        </Space>
      </Col>
    </Row>
  );
};
