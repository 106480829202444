import { AppContext } from '@logistic/common';
import { Col, Row, Space } from 'antd';
import { Footer } from 'antd/lib/layout/layout';
import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';

const AppFooter = () => {
  const { config } = useContext(AppContext);
  return (
    <Footer className="app-footer">
      <Row align="bottom" justify="space-between">
        <Col className="copyright">{config.copyright}</Col>
        <Col>
          <Space>
            <NavLink to="/home">{'主頁'}</NavLink>
            <NavLink to="/create_order">{'落單'}</NavLink>
            <NavLink to="/order">{'貨單'}</NavLink>
            <NavLink to="/pricing">{'收費'}</NavLink>
            <NavLink to="/faq">{'常見問題'}</NavLink>
          </Space>
        </Col>
      </Row>
    </Footer>
  );
};

export default AppFooter;
