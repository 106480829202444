import { message } from 'antd';
import axios from 'axios';
import firebase from 'firebase/app';
import AppConfig from '../config/config';
import { Store } from '../models/store';

const api = axios.create({
  baseURL: AppConfig.apiUrl,
  timeout: AppConfig.timeout,
});

export default class StoreService {
  static getAll = async function (): Promise<Store[]> {
    try {
      const response = await api.get('/get_stores');
      return response.data;
    } catch (e) {
      if (e?.response?.status == 400) message.warn('請稍後再試');
      return [];
    }
  };

  static batchUpdate = async function (body: { key: string; value: any }[]): Promise<Store[]> {
    try {
      const response = await api.post('/update_stores', body, { headers: { authorization: `Bearer ${await firebase.auth().currentUser?.getIdToken()}` } });
      return response.data;
    } catch (e) {
      if (e?.response?.status == 400) message.warn('請稍後再試');
      throw e;
    }
  };
}
