import Icon from '@ant-design/icons';
import { Button, Col, Form, Image, message, Modal, Row, Space, Typography } from 'antd';
import React, { ReactNode, useState } from 'react';
import { ReactComponent as AppLogo } from '../../assets/img/logo.svg';
import AppConstant from '../../constants/constant';
import { AdminRole } from '../../constants/userRole';
import { Admin, User } from '../../models/user';
import UserService from '../../services/userService';
import AppTheme from '../../theme/theme';
import { CloseIcon } from '../AppIcon';
import { DropdownInput, EmailInput, LineInput, PhoneInput } from '../form/AppForm';

export const InputModal = (props: {
  title: string;
  subtitle?: string;
  formItem: ReactNode;
  initialValues?: any;
  visible: boolean;
  onSubmit: (values: any) => Promise<void>;
  onChange?: (values: any) => void;
  onCancel: () => void;
  width?: number;
  disabled?: boolean;
}) => {
  const { title, subtitle, formItem, initialValues, visible, onSubmit, onChange, onCancel, width, disabled } = props;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values: any) => {
    try {
      setLoading(true);
      await onSubmit(values);
      onCancel();
    } finally {
      setLoading(false);
    }
  };

  return (
    <AppModal width={width} visible={visible} onCancel={onCancel} title={title} subtitle={subtitle}>
      <Form form={form} initialValues={initialValues} onFinish={onFinish} onValuesChange={onChange} layout="horizontal" preserve={false}>
        {formItem}
        <Row justify="center">
          <Form.Item noStyle shouldUpdate>
            {({ isFieldsTouched, getFieldsError }) => (
              <Button
                style={{ width: 240, marginTop: AppTheme.insetMd }}
                type="primary"
                htmlType="submit"
                size="large"
                block
                disabled={(initialValues && !isFieldsTouched(false)) || (!initialValues && !isFieldsTouched(true)) || !!getFieldsError().filter(({ errors }) => errors.length).length || disabled}
                loading={loading}
              >
                {'確認'}
              </Button>
            )}
          </Form.Item>
        </Row>
      </Form>
    </AppModal>
  );
};

export const AppModal = (props: { visible: boolean; onCancel: () => void; children: ReactNode; width?: number; title?: string; subtitle?: string; showLogo?: boolean }) => {
  const { visible, onCancel, children, showLogo, width = showLogo ? AppTheme.modalWidth : AppTheme.modalWidthSm, title, subtitle } = props;
  return (
    <Modal width={width} visible={visible} title={null} centered footer={null} onCancel={onCancel} destroyOnClose closeIcon={<CloseIcon style={{ fontSize: 24 }} />}>
      <Row>
        {showLogo && (
          <Col
            flex="240px"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: AppTheme.secondayColor,
              margin: -AppTheme.insetMd,
              marginRight: AppTheme.insetMd,
              borderTopLeftRadius: AppTheme.cardBorderRadius,
              borderBottomLeftRadius: AppTheme.cardBorderRadius,
            }}
          >
            <Icon component={AppLogo} style={{ fontSize: 120 }} />
          </Col>
        )}
        <Col flex="1">
          <Space direction="vertical" size={AppTheme.insetXss}>
            {title && (
              <Typography.Title style={{ marginTop: 40, textAlign: 'center' }} level={2}>
                {title}
              </Typography.Title>
            )}
            {subtitle && (
              <Typography.Text style={{ textAlign: 'center' }} type="secondary">
                {subtitle}
              </Typography.Text>
            )}
          </Space>
          {children}
        </Col>
      </Row>
    </Modal>
  );
};

export const ImageModal = (props: { visible: boolean; onCancel: () => void; image: string[] }) => {
  const { visible, onCancel, image } = props;
  return (
    <div style={{ display: 'none' }}>
      <Image.PreviewGroup preview={{ visible: visible, onVisibleChange: onCancel }}>
        {image.map((e, i) => (
          <Image key={i} width={200} src={e} />
        ))}
      </Image.PreviewGroup>
    </div>
  );
};

export const UpdateProfileModal = (props: { visible: boolean; user?: User; admin: Admin; onCancel: () => void; onSuccess: () => void }) => {
  const { visible, user, onCancel, onSuccess, admin } = props;
  const [loading, setLoading] = useState(false);

  const onFinish = async (values: any) => {
    const { name, phone, email, role } = values;
    await UserService.updateAdmin({
      id: admin.id,
      name: name,
      phone: phone,
      email: email,
      role: role,
    }).then(async () => {
      message.success('成功更新用戶!');
      onSuccess();
    });
  };

  const blockAdmin = async () => {
    setLoading(true);
    await UserService.update({ id: admin.userId, blocked: !admin.user.blocked })
      .then(async () => {
        message.success('成功刪除帳戶!');
        onSuccess();
      })
      .finally(() => setLoading(false));
  };

  return (
    <InputModal
      visible={visible}
      onCancel={onCancel}
      initialValues={admin}
      title="編輯員工帳戶"
      formItem={
        <React.Fragment>
          <LineInput name="name" label="名稱" required />
          <PhoneInput name="phone" label="電話" required />
          <EmailInput name="email" label="電郵地址" required />
          <DropdownInput name="role" label="身份" required options={[AdminRole.ADMIN, AdminRole.STAFF].map((e) => ({ label: AppConstant.adminRoleMap[e], value: e }))} />
          {admin.userId != user?.id && (
            <Row justify="center">
              <Button style={{ width: 240, marginTop: AppTheme.insetMd }} type="primary" size="large" block danger onClick={blockAdmin} loading={loading}>
                {'刪除此帳戶'}
              </Button>
            </Row>
          )}
        </React.Fragment>
      }
      onSubmit={onFinish}
    />
  );
};
