import { Col, List, PageHeader, Row, Space, Typography } from 'antd';
import moment from 'moment';
import React, { ReactNode, useContext, useState } from 'react';
import AppConstant from '../constants/constant';
import { AppContext } from '../context/context';
import { Payment } from '../models/payment';
import PaymentService from '../services/paymentService';
import AppTheme from '../theme/theme';
import Utils from '../utils/utils';
import { BookmarkButton, InvoiceButton, LinkButton, ReceiptButton } from './AppButton';
import AppCard from './AppCard';
import { InfoRow } from './AppLayout';
import { ImageModal } from './modal/AppModal';

export const PaymentOrderCard = (props: { payment: Payment }) => {
  const { payment } = props;
  return (
    <AppCard
      title="貨單"
      actions={
        <Row align="middle" justify="space-between">
          <Typography.Title level={5}>{`${payment.orders.length} 張貨單共付`}</Typography.Title>
          <Typography.Title level={5}>{`$${payment.amount}`}</Typography.Title>
        </Row>
      }
    >
      <List
        style={{ marginLeft: -AppTheme.insetMd, marginRight: -AppTheme.insetMd, marginBottom: -AppTheme.insetSm }}
        itemLayout="horizontal"
        dataSource={payment.orders}
        split={false}
        header={
          <Row style={{ borderBottom: `1px solid ${AppTheme.borderColor}`, marginLeft: AppTheme.insetMd, marginRight: AppTheme.insetMd }}>
            <Col flex="200px">
              <Typography.Text type="secondary">{'貨單'}</Typography.Text>
            </Col>
            <Col flex="1">
              <Typography.Text type="secondary">{'收件人'}</Typography.Text>
            </Col>
            <Typography.Text type="secondary">{'價錢'}</Typography.Text>
          </Row>
        }
        renderItem={(item, i) => (
          <Row
            style={{
              backgroundColor: i % 2 == 1 ? AppTheme.inputBackgroundColor : undefined,
              paddingTop: AppTheme.insetXs,
              paddingBottom: AppTheme.insetXs,
              paddingLeft: AppTheme.insetMd,
              paddingRight: AppTheme.insetMd,
            }}
            align="middle"
          >
            <Col flex="200px">
              <LinkButton to={`/order/${item.id}`}>{`貨單 ${Utils.formatOrderId(item.id)}`}</LinkButton>
            </Col>
            <Col flex="1">
              <Typography.Text>{item.receiverName}</Typography.Text>
            </Col>
            <Typography.Text>{`$${item.totalCharge}`}</Typography.Text>
          </Row>
        )}
      />
    </AppCard>
  );
};

export const PaymentTitle = (props: { payment: Payment; onBookmark?: () => void }) => {
  const { payment, onBookmark } = props;

  const toggleBookmark = (payment: Payment) => {
    try {
      if (onBookmark) {
        onBookmark();
      }
      PaymentService.bookmark({ paymentId: payment.id });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Row align="middle" justify="space-between">
      <Space direction="horizontal" size={AppTheme.insetXs}>
        <PageHeader title={`付款編號: ${Utils.formatOrderId(payment.id)}`} />
        <Typography.Text strong>{AppConstant.paymentStatusMap[payment.status]}</Typography.Text>
        {onBookmark && <BookmarkButton bookmarked={payment.bookmarked} onClick={() => toggleBookmark(payment)} />}
      </Space>
      <Space size={AppTheme.insetMd}>
        <InvoiceButton payment={payment} style={{ width: AppTheme.headerButtonWidth }} />
        <ReceiptButton payment={payment} style={{ width: AppTheme.headerButtonWidth }} />
      </Space>
    </Row>
  );
};

export const SlipCard = (props: { payment: Payment; extra?: ReactNode }) => {
  const { config } = useContext(AppContext);
  const { payment, extra } = props;
  const [imageModalVisible, setImageModalVisible] = useState(false);

  return (
    <AppCard title="付款">
      <Space direction="vertical">
        <Space direction="vertical" size={AppTheme.insetXss}>
          <InfoRow label={'狀態'} value={AppConstant.paymentStatusMap[payment.status]} />
          <InfoRow label={'日期'} value={moment(payment.createdAt).format('DD/MM/YYYY')} />
          <InfoRow label={'方式'} value={Utils.getPaymentChannelByKey(config.paymentChannel, payment.channel)?.name} />
          <InfoRow label={'入數證明'} value={' '} />
          <div>
            <img
              style={{ width: '100%', maxHeight: 400, objectFit: 'contain', border: `1px solid ${AppTheme.borderColor}`, padding: AppTheme.insetSm, cursor: 'pointer' }}
              src={payment.attachment}
              onClick={() => setImageModalVisible(true)}
            />
            <ImageModal visible={imageModalVisible} onCancel={() => setImageModalVisible(false)} image={[payment.attachment]} />
          </div>
        </Space>
        {extra}
      </Space>
    </AppCard>
  );
};
