export default class AppConstant {
  static pickupChannelMap = {
    PICKUP: '上門收件',
    MTR: '地鐵站收件',
  } as any;
  static deliverChannelMap = {
    DELIVER: '上門送件',
    MTR: '地鐵站派件',
  } as any;
  static goodsAdditionalMap = {
    FRAGILE: '易碎品',
    THIS_WAY_UP: '貨物向上',
  } as any;
  static orderStatusMap = {
    ALL: '所有',
    PAYMENT_PENDING: '待付款',
    PAYMENT_PROCESSING: '待確定',
    PAYMENT_REPLENISH: '補錢',
    PAYMENT_REJECTED: '拒絕付款',
    PICKUP_PENDING: '待收件',
    ASSIGNING_PICKUP_DRIVER: '安排收件司機',
    PICKUP_IN_PROGRESS: '收件中',
    PICKED_UP: '已收件',
    IN_TRANSIT: '運送中',
    ARRIVED: '已到倉',
    ASSIGNING_DELIVER_DRIVER: '安排派件司機',
    OUT_OF_DELIVERY: '派貨中',
    REARRANGE_PICKUP: '重新安排收件',
    REARRANGE_DELIVER: '重新安排派送',
    PICKUP_READY: '待取貨',
    CANCELLED: '已取消',
    DELIVERED: '完成單',
    FAILED: '異常單',
  } as any;
  static orderStatusColorMap = {
    PAYMENT_PENDING: '#F99432',
    PAYMENT_PROCESSING: '#F9E132',
    PAYMENT_REJECTED: '#32F9F3',
    PAYMENT_REPLENISH: '#B0F932',
    PICKUP_PENDING: '#F9E132',
    ASSIGNING_PICKUP_DRIVER: '#F9E132',
    PICKUP_IN_PROGRESS: '#F9E132',
    PICKED_UP: '#F9E132',
    IN_TRANSIT: '#F9E132',
    ARRIVED: '#F9E132',
    ASSIGNING_DELIVER_DRIVER: '#F9E132',
    OUT_OF_DELIVERY: '#F9E132',
    REARRANGE_PICKUP: '#FF5B5B',
    REARRANGE_DELIVER: '#FF5B5B',
    CANCELLED: '#FF5B5B',
    DELIVERED: '#CDCDCD',
    FAILED: '#FF5B5B',
  } as any;
  static orderStatusDescriptionMap = {
    PAYMENT_PENDING: '貨單尚未完成，待付款。',
    PAYMENT_PROCESSING: '貨單已完成付費，待公司確認入數證明。',
    PICKUP_PENDING: '已確認入數並已成功下單，正安排上門收件。',
    PICKED_UP: '取得貨件，並準備運送到貨倉。',
    IN_TRANSIT: '運送中',
    ARRIVED: '貨已件到達。',
    OUT_OF_DELIVERY: '送貨前將會以Whatsapp通知客人，而實際送貨時間由司機以電話聯絡客人確定。',
    REARRANGE_PICKUP: ' 派遞員未能成功收取貨件，正重新安排收件。',
    REARRANGE_DELIVER: ' 派遞員未能成功送出貨件，正重新安排派送。',
    PICKUP_READY: '貨件已到達門市。',
    CANCELLED: '已取消',
    DELIVERED: '完成單',
    FAILED: '異常單',
  } as any;
  static customerRoleMap = {
    ALL: '所有',
    INDIVIDUAL_CUSTOMER: '散客',
    COOPERATE_CUSTOMER: '公司',
  } as any;
  static customerRoleColorMap = {
    INDIVIDUAL_CUSTOMER: '#f9e132',
    COOPERATE_CUSTOMER: '#9bff46',
  } as any;
  static adminRoleMap = {
    ADMIN: '管理員',
    STAFF: '員工',
  } as any;
  static regionMap = {
    HKI: '香港島',
    NT: '新界',
    KL: '九龍',
  } as any;
  static paymentStatusMap = {
    ALL: '所有',
    PROCESSING: '待確認',
    REJECTED: '拒絕付款',
    APPROVED: '接受付款',
  } as any;
  static paymentTypeMap = {
    ALL: '所有',
    SINGLE: '單一付款',
    GROUP: '合併付款',
  } as any;
  static paymentStatusColorMap = {
    PROCESSING: '#f5a837',
    REJECTED: '#e02020',
    APPROVED: '#37c5f5',
  } as any;
  static paymentRejectReason = ['相片不清晰', '上傳金額與應付金額不一樣'];
  static deliverRecordTypeMap = {
    ALL: '所有',
    PICKUP: '收件',
    DELIVER: '派件',
  } as any;
  static deliverRecordStatusMap = {
    ALL: '所有',
    ASSIGNED: '待接收',
    ACCEPTED: '已接收',
    CANCELLED: '已取消',
    COMPLETED: '已完成',
  } as any;
  static inventoryTypeMap = {
    IN: '入貨',
    OUT: '出貨',
  } as any;
  static inventoryTypeColorMap = {
    IN: '#4DC474',
    OUT: '#FF5B5B',
  } as any;
}
