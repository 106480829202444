export default class AppTheme {
  static maxWidth = 1200;
  static modalWidthSm = 480;
  static modalWidth = 720;
  static modalWidthLg = 960;
  static leftColSpan = 15;
  static rightColSpan = 24 - AppTheme.leftColSpan;
  static insetLg = 40;
  static insetMd = 32;
  static insetSm = 24;
  static insetXs = 16;
  static insetXss = 12;
  static insetXsss = 8;
  static borderRadius = 32;
  static cardBorderRadius = 30;
  static infoRowHeight = 60;
  static filterHeight = 48;
  static borderColor = '#d9d9d9';
  static headerHeight = 80;
  static bookmarkColor = '#f9e132';
  static blockColor = '#f93232';
  static secondayColor = '#f9e132';
  static primaryColor = '#144144';
  static filterBackgroundColor = '#f5f5f5';
  static inputBackgroundColor = '#f5f5f5';
  static disabledColor = '#8C8C8C';
  static headerButtonWidth = 120;
}
