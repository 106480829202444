import { message } from 'antd';
import axios from 'axios';
import firebase from 'firebase/app';
import fileDownload from 'js-file-download';
import AppConfig from '../config/config';
import { Pagination } from '../models/pagination';
import { Payment } from '../models/payment';

const api = axios.create({
  baseURL: AppConfig.apiUrl,
  timeout: AppConfig.timeout,
});

export default class PaymentService {
  static create = async function (body: { orderId: number; channel: string; attachment: string; replenish: boolean }): Promise<Payment> {
    try {
      const response = await api.post('/create_payment', body, { headers: { authorization: `Bearer ${await firebase.auth().currentUser?.getIdToken()}` } });
      return response.data;
    } catch (e) {
      if (e?.response?.status == 400) message.warn('請稍後再試');
      throw e;
    }
  };

  static createGroupPayment = async function (body: { orderIds: number[]; channel: string; attachment: string; amount: number }): Promise<Payment> {
    try {
      const response = await api.post('/create_group_payment', body, { headers: { authorization: `Bearer ${await firebase.auth().currentUser?.getIdToken()}` } });
      return response.data;
    } catch (e) {
      if (e?.response?.status == 400) message.warn('請稍後再試');
      throw e;
    }
  };

  static get = async function (id: number | string): Promise<Payment> {
    try {
      const response = await api.get('/get_payment', { headers: { authorization: `Bearer ${await firebase.auth().currentUser?.getIdToken()}` }, params: { id: id } });
      return response.data;
    } catch (e) {
      throw e;
    }
  };

  static getAll = async function (params: {
    userId?: number;
    status?: string;
    createdAt?: string;
    bookmarked?: boolean;
    type?: string;
    limit: number;
    offset: number;
    orderBy: string;
    sort?: string;
  }): Promise<{ pagination: Pagination; payments: Payment[] }> {
    try {
      const response = await api.get('/get_payments', { headers: { authorization: `Bearer ${await firebase.auth().currentUser?.getIdToken()}` }, params: params });
      return response.data;
    } catch (e) {
      if (e?.response?.status == 400) message.warn('請稍後再試');
      throw e;
    }
  };

  static downloadInvoice = async function (id: number): Promise<void> {
    try {
      const response = await api.get('/download_invoice', { headers: { authorization: `Bearer ${await firebase.auth().currentUser?.getIdToken()}` }, params: { id }, responseType: 'blob' });
      return fileDownload(response.data, response.headers['content-disposition'].replace('attachment; filename=', ''));
    } catch (e) {
      if (e?.response?.status == 400) message.warn('請稍後再試');
      throw e;
    }
  };

  static downloadInvoices = async function (params: { ids: number[] }): Promise<void> {
    try {
      const response = await api.get('/download_invoices', {
        headers: { authorization: `Bearer ${await firebase.auth().currentUser?.getIdToken()}` },
        params: { ids: params.ids.join(',') },
        responseType: 'blob',
      });
      return fileDownload(response.data, `IV${Date.now()}.zip`);
    } catch (e) {
      if (e?.response?.status == 400) message.warn('請稍後再試');
      throw e;
    }
  };

  static downloadReceipt = async function (id: number): Promise<void> {
    try {
      const response = await api.get('/download_receipt', { headers: { authorization: `Bearer ${await firebase.auth().currentUser?.getIdToken()}` }, params: { id }, responseType: 'blob' });
      return fileDownload(response.data, response.headers['content-disposition'].replace('attachment; filename=', ''));
    } catch (e) {
      if (e?.response?.status == 400) message.warn('請稍後再試');
      throw e;
    }
  };

  static downloadReceipts = async function (params: { ids: number[] }): Promise<void> {
    try {
      const response = await api.get('/download_receipts', {
        headers: { authorization: `Bearer ${await firebase.auth().currentUser?.getIdToken()}` },
        params: { ids: params.ids.join(',') },
        responseType: 'blob',
      });
      return fileDownload(response.data, `RC${Date.now()}.zip`);
    } catch (e) {
      if (e?.response?.status == 400) message.warn('請稍後再試');
      throw e;
    }
  };

  static approve = async function (body: { id: number }): Promise<Payment> {
    try {
      const response = await api.post('/approve_payment', body, { headers: { authorization: `Bearer ${await firebase.auth().currentUser?.getIdToken()}` } });
      return response.data;
    } catch (e) {
      if (e?.response?.status == 400) message.warn('請稍後再試');
      throw e;
    }
  };

  static reject = async function (body: { id: number; reason: string }): Promise<Payment> {
    try {
      const response = await api.post('/reject_payment', body, { headers: { authorization: `Bearer ${await firebase.auth().currentUser?.getIdToken()}` } });
      return response.data;
    } catch (e) {
      if (e?.response?.status == 400) message.warn('請稍後再試');
      throw e;
    }
  };

  static bookmark = async function (body: { paymentId: number }): Promise<Payment> {
    try {
      const response = await api.post('/bookmark_payment', body, { headers: { authorization: `Bearer ${await firebase.auth().currentUser?.getIdToken()}` } });
      return response.data;
    } catch (e) {
      if (e?.response?.status == 400) message.warn('請稍後再試');
      throw e;
    }
  };
}
