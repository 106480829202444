import { Space, Typography } from 'antd';
import notFound from 'img/not_found.png';
import React from 'react';
import AppTheme from '../theme/theme';
import { SearchBar } from './form/AppForm';

export const NotFoundPlaceholder = (props: { title: string; subtitle: string; placeholder: string; onFinish: (id: string) => void }) => {
  const { title, subtitle, placeholder, onFinish } = props;
  return (
    <Space
      style={{ minHeight: 800, height: `calc(100vh - ${AppTheme.headerHeight}px - 100px)`, justifyContent: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      direction="vertical"
      size={AppTheme.insetMd}
    >
      <img src={notFound} />
      <Space style={{ textAlign: 'center' }} direction="vertical" size={AppTheme.insetXs}>
        <Typography.Title level={2}>{title}</Typography.Title>
        <Typography.Text>{subtitle}</Typography.Text>
      </Space>
      <SearchBar style={{ width: 300 }} onFinish={onFinish} placeholder={placeholder} />
    </Space>
  );
};
