export enum DeliverRecordStatus {
  ASSIGNED = 'ASSIGNED',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
}

export enum DeliverRecordType {
  PICKUP = 'PICKUP',
  DELIVER = 'DELIVER',
}
