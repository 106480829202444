import { AppModal, AppTheme, OtpInput, PhoneInput, Utils } from '@logistic/common';
import { Button, Col, Form, message, Row, Typography } from 'antd';
import { ClientContext } from 'App';
import firebase from 'firebase/app';
import React, { useContext, useState } from 'react';
import { auth, ConfirmationResult, RecaptchaVerifier } from 'service/firebase';

const LoginModal = () => {
  const { loginModalVisible, setLoginModalVisible, setCooperateLoginModalVisible } = useContext(ClientContext);
  const [otpSent, setOtpSent] = useState(false);
  const [sendingOtp, setSendingOtp] = useState(false);
  const [loggingIn, setLoggingIn] = useState(false);
  const [confirmationResult, setConfirmationResult] = useState<ConfirmationResult>();
  const [individaulForm] = Form.useForm();
  const [recaptchaVerifier, setRecaptchaVerifier] = useState<firebase.auth.RecaptchaVerifier>();

  const onIndividualFinish = async (values: any) => {
    try {
      setLoggingIn(true);
      await confirmationResult?.confirm(values['otp']);
      message.success('登入成功!');
      setLoginModalVisible(false);
    } catch (e) {
      if (e.code == 'auth/code-expired') message.warn('驗證碼過期!');
      if (e.code == 'auth/invalid-verification-code') message.warn('驗證碼錯誤!');
    } finally {
      setLoggingIn(false);
    }
  };

  const sendOtp = async () => {
    individaulForm.validateFields(['mobile']).then(async (e) => {
      try {
        setSendingOtp(true);
        const mobile = `+852${e.mobile}`;
        auth.useDeviceLanguage();
        let recap = recaptchaVerifier;
        if (!recaptchaVerifier) {
          recap = new RecaptchaVerifier('send-otp', {
            size: 'invisible',
            callback: () => setOtpSent(true),
          });
          setRecaptchaVerifier(recap);
        }
        if (recap) {
          await auth
            .signInWithPhoneNumber(mobile, recap)
            .then((confirmationResult) => {
              setConfirmationResult(confirmationResult);
              message.success(`已傳送驗證碼到 ${Utils.formatPhone(mobile.replace('+852', ''))}`);
            })
            .catch((error) => {
              console.error(error);
              message.error('發生錯誤 請稍後再試');
            });
        }
      } finally {
        setSendingOtp(false);
      }
    });
  };

  const onCooperateLogin = () => {
    setLoginModalVisible(false);
    setCooperateLoginModalVisible(true);
  };

  return (
    <AppModal title="散客登入" subtitle="以手機直接登入同時開戶(適用小量寄件)" visible={loginModalVisible} onCancel={() => setLoginModalVisible(false)} showLogo>
      <Form form={individaulForm} onFinish={onIndividualFinish} layout="horizontal" preserve={false}>
        <Row style={{ marginBottom: AppTheme.insetMd }} align="middle" justify="center" gutter={AppTheme.insetSm}>
          <Col flex="1">
            <PhoneInput name="mobile" label="手機號碼" required />
          </Col>
          <Col>
            <Form.Item noStyle shouldUpdate={(prevValues, curValues) => prevValues.mobile !== curValues.mobile}>
              {({ isFieldTouched, getFieldError }) => (
                <Button
                  id="send-otp"
                  style={{ padding: 0 }}
                  type="link"
                  disabled={!isFieldTouched('mobile') || !!getFieldError('mobile').filter((errors) => errors.length).length}
                  onClick={sendOtp}
                  loading={sendingOtp}
                >
                  {'發送驗證碼'}
                </Button>
              )}
            </Form.Item>
          </Col>
        </Row>
        <OtpInput name="otp" label="驗證碼" />
        <Row style={{ paddingTop: AppTheme.insetMd }} align={'middle'} justify={'space-between'}>
          <Col span={12}>
            <Typography.Text>
              <Typography.Link underline onClick={onCooperateLogin}>
                {'公司客戶登記/登入'}
              </Typography.Link>
            </Typography.Text>
          </Col>
          <Col span={12}>
            <Form.Item shouldUpdate>
              {({ isFieldsTouched, getFieldsError }) => (
                <Button
                  size="large"
                  type="primary"
                  htmlType="submit"
                  block
                  disabled={!otpSent || !isFieldsTouched(true) || !!getFieldsError().filter(({ errors }) => errors.length).length}
                  loading={loggingIn}
                >
                  {'登入'}
                </Button>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </AppModal>
  );
};

export default LoginModal;
