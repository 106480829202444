import { LoadingOutlined } from '@ant-design/icons';
import { Modal, Row, Spin } from 'antd';
import React from 'react';

const LoadingOverlay = (props: { visible: boolean }) => (
  <Modal visible={props.visible} centered closable={false} footer={null} width={86.5}>
    <Row justify="center">
      <Spin indicator={<LoadingOutlined spin />} />
    </Row>
  </Modal>
);

export default LoadingOverlay;
