import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React from 'react';

const LoadingIndicator = () => {
  return (
    <div
      style={{
        flex: 1,
        minHeight: 400,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Spin indicator={<LoadingOutlined spin size={40} />} />
    </div>
  );
};
export default LoadingIndicator;
