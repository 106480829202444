import { FormInstance, message } from 'antd';
import firebase from 'firebase/app';
import * as H from 'history';
import { User } from 'models/user';
import { v4 as uuidv4 } from 'uuid';
import AppConfig from '../config/config';
import { OrderStatus } from '../constants/orderStatus';
import { UserRole } from '../constants/userRole';
import { PaymentChannel, PaymentChannelItem } from '../models/config';
import { ChargeInfo, Goods, Order } from '../models/order';
import { Pricing } from '../models/pricing';
import { Store } from '../models/store';

export default class Utils {
  static calculateItemFee(item: any, pricing: Pricing) {
    let total = 0;
    const { firstCharge, firstWeight, weightCharge, weightUnit } = pricing;
    const weight = Number(item?.weight ?? 0);
    const weightOver = weight - firstWeight;
    total += firstCharge;
    if (weightOver > 0) total += Math.ceil(weightOver / weightUnit) * weightCharge;
    return total;
  }

  static normFile(e: any) {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  }

  static getPricing(pricing: Pricing[] | undefined, pickupChannel: string, deliverChannel: string): Pricing {
    return pricing?.find((item) => item.pickupChannel == pickupChannel && item.deliverChannel == deliverChannel) ?? ({} as Pricing);
  }

  static isLoggedIn() {
    return localStorage.getItem('loggedIn') == '1';
  }

  static isAdmin() {
    return localStorage.getItem('role') == 'ADMIN';
  }

  static isCooperateCustomer() {
    return localStorage.getItem('role') == UserRole.COOPERATE_CUSTOMER;
  }

  static isIndividualCustomer() {
    return localStorage.getItem('role') == UserRole.INDIVIDUAL_CUSTOMER;
  }

  static getStoreLabel = (store?: Store) => {
    return store ? `${store.name} - ${store.address}` : '';
  };

  static getMtrStationLabel = (matrStation?: Store) => {
    return matrStation ? `${matrStation.name}` : '';
  };

  static getPaymentChannelByKey(paymentChannel: PaymentChannel, key: string): PaymentChannelItem | undefined {
    return Object.values(paymentChannel).find((e) => e.key == key);
  }

  static uploadCoverPhoto(options: any) {
    const file = options.file as File;
    const uploadTask = firebase.storage().ref('cover_photo').child(uuidv4()).put(file);
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
        if (options.onProgress) options.onProgress({ percent: progress } as any);
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED:
            console.log('Upload is paused');
            break;
          case firebase.storage.TaskState.RUNNING:
            console.log('Upload is running');
            break;
        }
      },
      options.onError,
      () =>
        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          console.log('File available at', downloadURL);
          if (options.onSuccess) options.onSuccess(downloadURL, {} as any);
        })
    );
  }

  static uploadDepositSlip(options: any, user: User) {
    const file = options.file as File;
    const validSize = file.size / 1024 / 1024 < AppConfig.depositSlipSizeLimit;
    if (!validSize) {
      message.error(`上載照片不超過${AppConfig.depositSlipSizeLimit}M`);
      return;
    }
    const uploadTask = firebase.storage().ref('user').child(Utils.formatCustomerId(user.id)).put(file);
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        if (options.onProgress) options.onProgress({ percent: progress } as any);
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED:
            break;
          case firebase.storage.TaskState.RUNNING:
            break;
        }
      },
      options.onError,
      () =>
        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          if (options.onSuccess) options.onSuccess(downloadURL, {} as any);
        })
    );
  }

  static parseGoodsFormData = (goods: any[]): Goods[] => {
    return goods.map((e) => ({
      depth: Number(e.depth ?? 0),
      width: Number(e.width ?? 0),
      height: Number(e.height ?? 0),
      weight: Number(e.weight ?? 0),
      description: e.description,
      remark: e.remark,
      fragile: e.additional?.includes('FRAGILE') ?? false,
      thisWayUp: e.additional?.includes('THIS_WAY_UP') ?? false,
      package: e.package,
    }));
  };

  static search = (history: H.History, id: string, onSuccess?: () => void) => {
    if (id && /^\d+$/.test(id)) {
      history.push(`/order/${id}`);
      if (onSuccess != null) onSuccess();
    } else {
      message.warn('錯誤的貨單編號');
    }
  };

  static parseOrderFormData = (infoForm: FormInstance, chargeInfo?: ChargeInfo): Order => {
    const data = {
      ...infoForm.getFieldsValue([
        'senderName',
        'senderMobile',
        'pickupChannel',
        'pickupDistrict',
        'pickupAddress',
        'pickupArea',
        'pickupFloor',
        'pickupMtrStation',
        'receiverName',
        'receiverMobile',
        'receiverBackupPhone',
        'deliverChannel',
        'deliverDistrict',
        'deliverAddress',
        'deliverArea',
        'deliverFloor',
        'deliverMtrStation',
        'pricingId',
      ]),
      goods: Utils.parseGoodsFormData(infoForm.getFieldValue('goods').map((e: any) => e ?? {})),
      ...chargeInfo,
    };
    if (data.pickupFloor == '') data.pickupFloor = 0;
    if (data.deliverFloor == '') data.deliverFloor = 0;
    return data;
  };

  static formatPhone = (phone?: string) => {
    return phone?.toString().replace(/(\d{4})(\d{4})/, '$1 $2') ?? '-';
  };

  static formatOrderId = (orderId?: number) => {
    return orderId?.toString()?.padStart(9, '0') ?? '';
  };

  static formatCustomerId = (customerId?: number) => {
    return customerId?.toString()?.padStart(4, '0') ?? '';
  };

  static formatDriverId = (driverId?: number) => {
    return `D${driverId?.toString()?.padStart(4, '0')}`;
  };

  static formatPaymentId = (paymentId?: number) => {
    return `P${paymentId?.toString()?.padStart(9, '0')}`;
  };

  static orderPayable = (order: Order) => {
    return [OrderStatus.PAYMENT_PENDING, OrderStatus.PAYMENT_REJECTED].includes(order.status as OrderStatus);
  };

  static formatPricing = (pricing: Pricing) => {
    const { firstWeight, firstCharge, weightUnit, weightCharge, minCharge } = pricing;
    return `首 ${firstWeight}kg $${firstCharge}, 其後 每 ${weightUnit}kg +$${weightCharge}${minCharge > 0 ? `, 最低收費 $${minCharge}` : ''}`;
  };
}
