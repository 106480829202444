/* eslint-disable react/display-name */
import { Affix, Button, Card, Row, Space, Typography } from 'antd';
import { ColumnsType, SorterResult } from 'antd/lib/table/interface';
import { AppContext } from 'context/context';
import moment from 'moment';
import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { BooleanParam, NumberParam, StringParam, useQueryParam, withDefault } from 'use-query-params';
import AppConstant from '../../constants/constant';
import { Order } from '../../models/order';
import { Pagination } from '../../models/pagination';
import { User } from '../../models/user';
import OrderService from '../../services/orderService';
import AppTheme from '../../theme/theme';
import Utils from '../../utils/utils';
import { BookmarkButton, LinkButton } from '../AppButton';
import { CloseIcon } from '../AppIcon';
import { OrderStatusBox } from '../OrderInfo';
import { AppTable, DateRangeFilter, SelectFilter } from './AppTable';

const OrderTable = (props: {
  user?: User;
  showBookmark?: boolean;
  showReceiver?: boolean;
  showDistrict?: boolean;
  senderId?: number;
  extraAction?: (selected: Order[], clearSelected: () => void, reload: () => void) => ReactNode;
}) => {
  const { user, showBookmark, showReceiver, showDistrict, senderId, extraAction } = props;
  const { config } = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<Order[]>();
  const [pagination, setPagination] = useState<Pagination>();
  const [page, setPage] = useQueryParam('page', withDefault(NumberParam, 1));
  const [pageSize, setPageSize] = useQueryParam('page_size', withDefault(NumberParam, 20));
  const [sortBy, setSortBy] = useQueryParam('sort_by', withDefault(StringParam, 'createdAt'));
  const [sort, setSort] = useQueryParam('sort', StringParam);
  const [date, setDate] = useQueryParam('date', StringParam);
  const [bookmarked, setBookmarked] = useQueryParam('bookmarked', BooleanParam);
  const [status, setStatus] = useQueryParam('status', withDefault(StringParam, 'ALL'));
  const [pickupDistrict, setPickupDistrict] = useQueryParam('pickupDistrict', withDefault(StringParam, 'ALL'));
  const [deliverDistrict, setDeliverDistrict] = useQueryParam('deliverDistrict', withDefault(StringParam, 'ALL'));
  const [downloading, setDownloading] = useState(false);

  useEffect(() => {
    if (user) {
      getData();
    }
  }, [page, user, pageSize, sortBy, sort, status, date, bookmarked, pickupDistrict, deliverDistrict]);

  const getData = () => {
    setLoading(true);
    OrderService.getOrders({
      senderId: senderId,
      status: status == 'ALL' ? undefined : status,
      pickupDistrict: pickupDistrict == 'ALL' ? undefined : pickupDistrict,
      deliverDistrict: deliverDistrict == 'ALL' ? undefined : deliverDistrict,
      bookmarked: bookmarked == true ? true : undefined,
      createdAt: date as string | undefined,
      limit: pageSize,
      offset: (page - 1) * pageSize,
      orderBy: sortBy,
      sort: sort == 'ascend' ? 'ASC' : sort == 'descend' ? 'DESC' : undefined,
    })
      .then((e) => {
        setData(e?.orders);
        setPagination(e?.pagination);
      })
      .finally(() => setLoading(false));
  };

  const onSorterChange = (sorter: SorterResult<Order> | SorterResult<Order>[]) => {
    setSortBy((sorter as any).order ? (sorter as any).field : 'createdAt');
    setSort((sorter as any).order);
  };

  const onPageChange = (page: number, pageSize?: number) => {
    setPage(page);
    setPageSize(pageSize);
  };

  const toggleBookmark = (order: Order) => {
    try {
      OrderService.bookmarkOrder({ orderId: order.id });
      const newData = data?.map((e) => {
        if (e.id == order.id) {
          e.bookmarked = !order.bookmarked;
        }
        return e;
      });
      setData(newData);
    } catch (e) {
      console.log(e);
    }
  };

  const downloadWaybill = async (order: Order[]) => {
    setDownloading(true);
    OrderService.downloadWaybills({ ids: order.map((e) => e.id) }).finally(() => setDownloading(false));
  };

  const columns: ColumnsType<Order> = [
    ...(showBookmark
      ? [
          {
            dataIndex: 'bookmarked',
            key: 'bookmarked',
            width: 70,
            render: (bookmarked: boolean, record: Order) => <BookmarkButton bookmarked={bookmarked} onClick={() => toggleBookmark(record)} />,
          },
        ]
      : []),
    {
      title: '貨單編號',
      dataIndex: 'id',
      key: 'id',
      width: 120,
      render: (id: number) => <LinkButton to={`/order/${id}`}>{Utils.formatOrderId(id)}</LinkButton>,
    },
    {
      title: '落單日期',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 140,
      sorter: () => 0,
      render: (createdAt: Date) => <Typography.Text>{moment(createdAt).format('DD/MM/YYYY')}</Typography.Text>,
    },
    ...(showReceiver
      ? [
          {
            title: '收件人',
            dataIndex: 'receiverName',
            key: 'receiverName',
            width: 180,
            ellipsis: { showTitle: false },
            render: (receiverName: string) => <Typography.Text>{receiverName}</Typography.Text>,
          },
        ]
      : []),
    {
      title: '金額',
      dataIndex: 'totalCharge',
      key: 'totalCharge',
      width: 80,
      render: (totalCharge: number) => <Typography.Text>{`$${totalCharge}`}</Typography.Text>,
    },
    ...(showDistrict
      ? [
          {
            title: '收件地區',
            dataIndex: 'pickupDistrict',
            key: 'pickupDistrict',
            width: 120,
            render: (pickupDistrict: string) => <Typography.Text>{`${pickupDistrict ?? '-'}`}</Typography.Text>,
          },
          {
            title: '派件地區',
            dataIndex: 'deliverDistrict',
            key: 'deliverDistrict',
            width: 120,
            render: (deliverDistrict: string) => <Typography.Text>{`${deliverDistrict ?? '-'}`}</Typography.Text>,
          },
        ]
      : []),
    {
      title: '狀態',
      dataIndex: 'status',
      key: 'status',
      align: 'right',
      ellipsis: { showTitle: false },
      render: (status: string) => <OrderStatusBox status={status} />,
    },
    {
      title: '更新時間',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      width: 190,
      sorter: () => 0,
      align: 'right',
      render: (updatedAt: Date) => <Typography.Text>{moment(updatedAt).format('DD/MM/YYYY, HH:mm')}</Typography.Text>,
    },
  ];

  return (
    <AppTable
      data={data ?? []}
      columns={columns}
      loading={loading}
      filter={
        <Space style={{ maxWidth: 1000 }} wrap size={[AppTheme.insetMd, AppTheme.insetSm]}>
          <SelectFilter
            label="狀態"
            width={140}
            defaultValue={status}
            onChange={setStatus}
            options={Object.keys(AppConstant.orderStatusMap).map((e) => ({ label: AppConstant.orderStatusMap[e], value: e }))}
          />
          {showDistrict && (
            <React.Fragment>
              <SelectFilter
                label="收件地區"
                width={140}
                defaultValue={pickupDistrict}
                onChange={setPickupDistrict}
                options={[{ label: '所有', value: 'ALL' }, ...config.district.map((e) => ({ label: e, value: e }))]}
              />
              <SelectFilter
                label="寄件地區"
                width={140}
                defaultValue={deliverDistrict}
                onChange={setDeliverDistrict}
                options={[{ label: '所有', value: 'ALL' }, ...config.district.map((e) => ({ label: e, value: e }))]}
              />
            </React.Fragment>
          )}
          <DateRangeFilter label="落單日期" onChange={(e) => setDate(e?.map((d) => d?.format('YYYY-MM-DD')).join(','))} />
        </Space>
      }
      extra={<Space size={AppTheme.insetXs}>{showBookmark && <BookmarkButton bookmarked={bookmarked} onClick={() => setBookmarked(!bookmarked)} filled />}</Space>}
      pagination={pagination}
      page={page}
      pageSize={pageSize}
      onSorterChange={onSorterChange}
      onPageChange={onPageChange}
      selectable
      extraAction={(selected: Order[], clear) => (
        <Affix offsetBottom={AppTheme.insetLg}>
          <Card style={{ display: selected.length > 0 ? 'block' : 'none' }} bordered={false}>
            <Row align="middle" justify="space-between">
              <Typography.Title level={5}>{`選取共 ${selected.length} 張貨單`}</Typography.Title>
              <Space size={AppTheme.insetMd}>
                {extraAction && extraAction(selected, clear, getData)}
                <Button type="primary" size="large" block onClick={() => downloadWaybill(selected)} loading={downloading}>
                  {'下載貨單'}
                </Button>
                <Button onClick={clear} icon={<CloseIcon style={{ fontSize: 24 }} />} />
              </Space>
            </Row>
          </Card>
        </Affix>
      )}
    />
  );
};
export default OrderTable;
