import { List, PageHeader, Row, Space, Typography } from 'antd';
import { Statement } from 'models/statement';
import moment from 'moment';
import React, { ReactNode, useEffect, useState } from 'react';
import StatementService from 'services/statmentService';
import AppConstant from '../constants/constant';
import { OrderStatus } from '../constants/orderStatus';
import { UserRole } from '../constants/userRole';
import { OrderCount } from '../models/order';
import { User } from '../models/user';
import OrderService from '../services/orderService';
import UserService from '../services/userService';
import AppTheme from '../theme/theme';
import Utils from '../utils/utils';
import { BlockButton, BookmarkButton } from './AppButton';
import AppCard from './AppCard';
import { InfoRow } from './AppLayout';

export const CustomerCard = (props: { customer: User; extra?: ReactNode }) => {
  const { customer, extra } = props;
  return (
    <AppCard title="帳戶資料" extra={extra}>
      <Space direction="vertical" size={AppTheme.insetXss}>
        {customer.role == UserRole.COOPERATE_CUSTOMER && (
          <React.Fragment>
            <InfoRow label="公司名稱" value={customer.name} />
            <InfoRow label="電話" value={Utils.formatPhone(customer.phone)} />
            <InfoRow label="電郵地址" value={customer.email} />
            <InfoRow label="公司地址" value={customer.address} />
          </React.Fragment>
        )}
        {customer.role == UserRole.INDIVIDUAL_CUSTOMER && (
          <React.Fragment>
            <InfoRow label="名稱" value={customer.name} />
            <InfoRow label="手機號碼" value={Utils.formatPhone(customer.mobile)} />
            <InfoRow label="地址" value={customer.address} />
          </React.Fragment>
        )}
      </Space>
    </AppCard>
  );
};

export const CustomerTitle = (props: { customer?: User; onBookmarked?: (customer: User) => void; onBlocked?: (customer: User) => void; extra?: ReactNode }) => {
  const { customer, onBookmarked, onBlocked, extra } = props;

  const toggleBlocked = () => {
    if (onBlocked && customer) {
      UserService.update({ id: customer.id, blocked: !customer.blocked });
      onBlocked({ ...customer, blocked: !customer.blocked });
    }
  };

  const toggleBookmark = () => {
    if (onBookmarked && customer) {
      UserService.bookmark({ userId: customer.id });
      onBookmarked({ ...customer, bookmarked: !customer.bookmarked });
    }
  };

  return (
    <Row align="middle" justify="space-between">
      <Space size={0}>
        <PageHeader title={customer?.name} />
        {onBookmarked && customer && <BookmarkButton bookmarked={customer.bookmarked} onClick={() => toggleBookmark()} />}
        {onBlocked && customer && <BlockButton blocked={customer.blocked} onClick={() => toggleBlocked()} />}
      </Space>
      <Space size={AppTheme.insetMd}>
        <Typography.Text>{`帳戶編號: ${Utils.formatCustomerId(customer?.id)}`}</Typography.Text>
        {extra}
      </Space>
    </Row>
  );
};

export const CustomerOrderCard = (props: { customer: User; extra?: ReactNode }) => {
  const { customer, extra } = props;
  const [orderCount, setOrderCount] = useState<OrderCount[]>();
  const [statment, setStatement] = useState<Statement[]>([]);

  useEffect(() => {
    OrderService.getOrderCount({ userId: customer.id }).then((e) => setOrderCount(e));
    if (customer.role == UserRole.COOPERATE_CUSTOMER && customer.monthlyBillingEnabled) {
      StatementService.getAll({}).then((e) => setStatement(e.statements));
    }
  }, []);

  const getOrderTotalCount = () => {
    return orderCount?.reduce((sum, { count }) => sum + count, 0) ?? 0;
  };

  const getOrderSuccessRate = () => {
    const successCount = orderCount?.find((e) => e.status == OrderStatus.DELIVERED);
    return `${Math.round((getOrderTotalCount() == 0 ? 0 : ((successCount?.count ?? 0) / getOrderTotalCount()) * 100) * 100) / 100}%`;
  };

  return (
    <AppCard title="貨單記錄" extra={extra}>
      <Space direction="vertical" size={AppTheme.insetXss}>
        <InfoRow label="貨單總數" value={getOrderTotalCount().toString()} />
        <InfoRow label="完成率" value={getOrderSuccessRate()} />
        {!!statment.length && (
          <List
            style={{ marginLeft: -AppTheme.insetMd, marginRight: -AppTheme.insetMd }}
            itemLayout="horizontal"
            dataSource={statment}
            renderItem={(item, i) => (
              <Row style={{ backgroundColor: i % 2 == 1 ? AppTheme.inputBackgroundColor : undefined, padding: `${AppTheme.insetXs}px ${AppTheme.insetMd}px` }} align="middle" justify="space-between">
                <Typography.Link
                  underline
                  onClick={() => {
                    StatementService.download(item.id);
                  }}
                >{`${moment(item.createdAt).format('MM/YYYY')} 月結單`}</Typography.Link>
                <Typography.Text>{`$${item.totalCharge}/${item.totalCount}單`}</Typography.Text>
              </Row>
            )}
          />
        )}
      </Space>
    </AppCard>
  );
};

export const RoleTag = (props: { role: string }) => {
  const { role } = props;
  return (
    <Row>
      <Typography.Text style={{ backgroundColor: AppConstant.customerRoleColorMap[role] }} strong>
        {AppConstant.customerRoleMap[role]}
      </Typography.Text>
    </Row>
  );
};
