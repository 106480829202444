import { AppConstant, OrderStatus, Utils } from '@logistic/common';
import { ClientContext } from 'App';
import React, { useContext } from 'react';
import { ResultModal } from './ResultModal';

const OrderResultModal = () => {
  const { order, orderResultModalVisible, setOrderResultModalVisible } = useContext(ClientContext);
  return (
    <ResultModal
      visible={orderResultModalVisible}
      onCancel={() => setOrderResultModalVisible(false)}
      title={AppConstant.orderStatusMap[order?.status ?? '']}
      subtitle={order?.status == OrderStatus.PAYMENT_PENDING ? '落單完成，所有貨單已儲存於”貨單” ' : '完成付費，我們將於2個工作天內確認貨單。'}
      resultId={Utils.formatOrderId(order?.id)}
    />
  );
};

export default OrderResultModal;
