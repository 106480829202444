import { Button, Col, ConfigProvider, Row, Space, Typography } from 'antd';
import locale from 'antd/es/locale/zh_HK';
import React, { CSSProperties, ReactNode, Suspense, useState } from 'react';
import AppConfig from '../config/config';
import { default as AppTheme, default as Theme } from '../theme/theme';
import LoadingIndicator from './LoadingIndicator';

export const AppConfigProvider = (props: { children: ReactNode; renderEmpty?: () => ReactNode }) => {
  const { children, renderEmpty } = props;
  return (
    <ConfigProvider prefixCls={AppConfig.antPrefixCls} space={{ size: AppTheme.insetLg }} locale={locale} renderEmpty={renderEmpty}>
      {children}
    </ConfigProvider>
  );
};

export const AppRow = (props: { children: ReactNode; style?: CSSProperties }) => {
  const { children, style } = props;
  return (
    <Row style={style} gutter={AppTheme.insetLg}>
      {children}
    </Row>
  );
};

export const AppLeftCol = (props: { children: ReactNode; style?: CSSProperties }) => {
  const { children, style } = props;
  return (
    <Col style={style} flex="1">
      {children}
    </Col>
  );
};

export const AppRightCol = (props: { children: ReactNode; style?: CSSProperties }) => {
  const { children, style } = props;
  return (
    <Col style={style} span={Theme.rightColSpan}>
      {children}
    </Col>
  );
};

export const App2ColLayout = (props: { style?: CSSProperties; left: ReactNode; right?: ReactNode; leftStyle?: CSSProperties; rightStyle?: CSSProperties }) => {
  const { style, left, right, leftStyle, rightStyle } = props;
  return (
    <AppRow style={style}>
      <AppLeftCol style={leftStyle}>{left}</AppLeftCol>
      {right && <AppRightCol style={rightStyle}>{right}</AppRightCol>}
    </AppRow>
  );
};

export const InfoRow = (props: { label: string; value?: string | number; height?: number; strong?: boolean; bordered?: boolean; style?: CSSProperties }) => {
  const { label, value, height, bordered, strong, style } = props;
  return (
    <Row style={{ height: height, borderBottom: bordered ? `1px solid ${AppTheme.borderColor}` : undefined, ...style }} align="middle" justify="space-between">
      <Col style={{ maxWidth: '70%' }}>
        <Typography.Text type="secondary">{label}</Typography.Text>
      </Col>
      <Col style={{ minWidth: '30%', textAlign: 'end' }}>
        <Typography.Text strong={strong}>{value ?? '-'}</Typography.Text>
      </Col>
    </Row>
  );
};

export const AppTabView = (props: { tabs: { component: ReactNode; tab: string; key: string }[]; defaultActiveKey: string; onChange?: (activeKey: string) => void; tabbarStyle?: CSSProperties }) => {
  const { tabs, defaultActiveKey, onChange, tabbarStyle } = props;
  const [activeKey, setActivekey] = useState(defaultActiveKey ?? tabs[0].key);
  return (
    <Space direction="vertical" size={0}>
      <Space style={tabbarStyle} size={AppTheme.insetMd}>
        {tabs.map((e) => (
          <Button
            key={e.key}
            type="primary"
            size="large"
            style={{
              textShadow: 'none',
              backgroundColor: activeKey == e.key ? undefined : AppTheme.inputBackgroundColor,
              color: activeKey == e.key ? undefined : AppTheme.disabledColor,
              fontSize: 20,
            }}
            onClick={() => {
              setActivekey(e.key);
              if (onChange) onChange(e.key);
            }}
          >
            {e.tab}
          </Button>
        ))}
      </Space>
      <Suspense fallback={<LoadingIndicator />}>{tabs.find((e) => e.key == activeKey)?.component}</Suspense>
    </Space>
  );
};
