/* eslint-disable react/display-name */
import { Row, Typography } from 'antd';
import { ColumnsType, SorterResult } from 'antd/lib/table/interface';
import moment from 'antd/node_modules/moment';
import React, { useEffect, useState } from 'react';
import { NumberParam, StringParam, useQueryParam, withDefault } from 'use-query-params';
import { LinkButton } from '../../components/AppButton';
import { Inventory } from '../../models/inventory';
import { Pagination } from '../../models/pagination';
import { User } from '../../models/user';
import InventoryService from '../../services/inventoryService';
import AppTheme from '../../theme/theme';
import { AppTable } from './AppTable';

const InventoryTable = (props: { user: User }) => {
  const { user } = props;
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<Inventory[]>();
  const [pagination, setPagination] = useState<Pagination>();
  const [page, setPage] = useQueryParam('page', withDefault(NumberParam, 1));
  const [pageSize, setPageSize] = useQueryParam('page_size', withDefault(NumberParam, 20));
  const [sortBy, setSortBy] = useQueryParam('sort_by', withDefault(StringParam, 'createdAt'));
  const [sort, setSort] = useQueryParam('sort', StringParam);

  useEffect(() => {
    if (user) {
      getData();
    }
  }, [page, user, pageSize, sortBy, sort]);

  const getData = () => {
    setLoading(true);
    InventoryService.getInventories({
      limit: pageSize,
      offset: (page - 1) * pageSize,
      orderBy: sortBy,
      sort: sort == 'ascend' ? 'ASC' : sort == 'descend' ? 'DESC' : undefined,
    })
      .then((e) => {
        setData(e?.inventories);
        setPagination(e?.pagination);
      })
      .finally(() => setLoading(false));
  };

  const onSorterChange = (sorter: SorterResult<Inventory> | SorterResult<Inventory>[]) => {
    setSortBy((sorter as any).order ? (sorter as any).field : 'createdAt');
    setSort((sorter as any).order);
  };

  const onPageChange = (page: number, pageSize?: number) => {
    setPage(page);
    setPageSize(pageSize);
  };

  const columns: ColumnsType<Inventory> = [
    {
      title: '貨品編號',
      dataIndex: 'itemId',
      key: 'itemId',
      width: 150,
      render: (itemId: string) => <Typography.Text>{itemId}</Typography.Text>,
    },
    {
      title: '貨品',
      dataIndex: 'name',
      key: 'name',
      ellipsis: { showTitle: false },
      render: (name: string, record: Inventory) => <LinkButton to={`/inventory/${record.id}`}>{name}</LinkButton>,
    },
    {
      title: 'SKU',
      dataIndex: 'sku',
      key: 'sku',
      align: 'right',
      ellipsis: { showTitle: false },
      render: (sku: string) => <Typography.Text>{sku}</Typography.Text>,
    },
    {
      title: '到期日',
      dataIndex: 'expiryDate',
      key: 'expiryDate',
      align: 'right',
      width: 150,
      sorter: () => 0,
      render: (expiryDate: string) => <Typography.Text>{moment(expiryDate).format('DD/MM/YYYY')}</Typography.Text>,
    },
    {
      title: '每箱數量',
      dataIndex: 'quantityPerBox',
      key: 'quantityPerBox',
      align: 'right',
      width: 120,
      render: (quantityPerBox: number) => <Typography.Text>{quantityPerBox?.toLocaleString()}</Typography.Text>,
    },
    {
      title: '總數量',
      dataIndex: 'total',
      key: 'total',
      align: 'right',
      width: 120,
      render: (total: number) => <Typography.Text>{total?.toLocaleString()}</Typography.Text>,
    },
    {
      title: '結餘',
      dataIndex: 'remaining',
      key: 'remaining',
      width: 120,
      align: 'right',
      render: (remaining: number) => (
        <Row justify="end">
          <Typography.Text style={{ backgroundColor: AppTheme.secondayColor }} strong>
            {remaining.toLocaleString()}
          </Typography.Text>
        </Row>
      ),
    },
  ];

  return <AppTable data={data ?? []} columns={columns} loading={loading} pagination={pagination} page={page} pageSize={pageSize} onSorterChange={onSorterChange} onPageChange={onPageChange} />;
};
export default InventoryTable;
