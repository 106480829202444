const mtrStation = [
  {
    line: '觀塘綫',
    station: ['藍田', '觀塘', '牛頭角', '九龍灣', '彩虹', '鑽石山', '黃大仙', '樂富', '九龍塘', '石硤尾', '太子', '旺角', '油麻地', '何文田', '黃埔'],
  },
  {
    line: '荃灣綫',
    station: ['荃灣', '大窩口', '葵興', '葵芳', '荔景', '美孚', '荔枝角', '長沙灣', '深水埗', '太子', '旺角', '油麻地', '佐敦', '尖沙咀'],
  },
  {
    line: '港島綫',
    station: ['堅尼地城', '香港大學', '西營盤', '上環', '中環', '金鐘', '灣仔', '銅鑼灣', '天后', '炮台山', '北角', '鰂魚涌', '太古', '西灣河', '筲箕灣', '杏花邨', '柴灣'],
  },
  {
    line: '南港島綫',
    station: ['金鐘', '海洋公園', '黃竹坑', '利東', '海怡半島'],
  },
  {
    line: '將軍澳綫',
    station: ['康城', '寶琳', '坑口', '將軍澳', '調景嶺', '油塘'],
  },
  {
    line: '東涌綫及迪士尼綫',
    station: ['香港', '九龍', '奧運', '南昌', '荔景', '青衣', '欣澳', '迪士尼', '東涌'],
  },
  {
    line: '東鐵綫',
    station: ['紅磡', '旺角東', '九龍塘', '大圍', '沙田', '火炭', '馬場', '大學', '大埔墟', '太和', '粉嶺', '上水', '羅湖', '落馬洲'],
  },
  {
    line: '屯馬綫一期',
    station: ['啟德', '鑽石山', '顯徑', '大圍', '車公廟', '沙田圍', '第一城', '石門', '大水坑', '恒安', '馬鞍山', '烏溪沙'],
  },
  {
    line: '西鐵綫',
    station: ['尖東', '柯士甸', '南昌', '美孚', '荃灣西', '錦上路', '元朗', '朗屏', '天水圍', '兆康', '屯門'],
  },
];

export default mtrStation;
