import { message } from 'antd';
import axios from 'axios';
import firebase from 'firebase/app';
import fileDownload from 'js-file-download';
import { Pagination } from 'models/pagination';
import { Statement } from 'models/statement';
import AppConfig from '../config/config';

const api = axios.create({
  baseURL: AppConfig.apiUrl,
  timeout: AppConfig.timeout,
});

export default class StatementService {
  static getAll = async function (params: { userId?: number; limit?: number; offset?: number; orderBy?: string; sort?: string }): Promise<{ pagination: Pagination; statements: Statement[] }> {
    try {
      const response = await api.get('/get_statements', { headers: { authorization: `Bearer ${await firebase.auth().currentUser?.getIdToken()}` }, params });
      return response.data;
    } catch (e) {
      if (e?.response?.status == 400) message.warn('請稍後再試');
      throw e;
    }
  };

  static download = async function (id: number): Promise<void> {
    try {
      const response = await api.get('/download_statement', { headers: { authorization: `Bearer ${await firebase.auth().currentUser?.getIdToken()}` }, params: { id }, responseType: 'blob' });
      return fileDownload(response.data, response.headers['content-disposition'].replace('attachment; filename=', ''));
    } catch (e) {
      if (e?.response?.status == 400) message.warn('請稍後再試');
      throw e;
    }
  };
}
