export default class AppConfig {
  static appName = 'speedie';
  static adminConfig = {
    apiKey: 'AIzaSyA-rbx4dSwRfXoL2h1ij2hthE-9FWtEg2k',
    authDomain: 'speedie-logistic.firebaseapp.com',
    projectId: 'speedie-logistic',
    storageBucket: 'speedie-logistic.appspot.com',
    messagingSenderId: '57579380180',
    appId: '1:57579380180:web:37bc963c302de2133e4a41',
    measurementId: 'G-TYQ90BBMHF',
  };
  static clientConfig = {
    apiKey: 'AIzaSyA-rbx4dSwRfXoL2h1ij2hthE-9FWtEg2k',
    authDomain: 'speedie-logistic.firebaseapp.com',
    projectId: 'speedie-logistic',
    storageBucket: 'speedie-logistic.appspot.com',
    messagingSenderId: '57579380180',
    appId: '1:57579380180:web:179d7e768ee3f2093e4a41',
    measurementId: 'G-4H42G0TT22',
  };
  static depositSlipSizeLimit = 10;
  static apiUrl = process.env.NODE_ENV == 'production' ? 'https://asia-east2-speedie-logistic.cloudfunctions.net/api/v1' : 'http://localhost:5003/speedie-logistic/asia-east2/api/v1';
  static timeout = 30000;
  static antPrefixCls = 'speedie';

  /* Admin */
  static senderInfoEditable = true;
  static receiverInfoEditable = true;
  static goodsInfoEditable = true;
  static createOrderConfigEnabled = false;
  static mtrChannelEanbled = true;
  static storeEnabled = false;

  /* Client */
  static excelUploadEnabled = true;
  static multiGoodsEnabled = false;

  static excelSchema = {
    收件人姓名: { prop: 'receiverName', type: String, required: true },
    收件人手機號碼: { prop: 'receiverMobile', type: String, required: true },
    派件地鐵站: { prop: 'deliverMtrStation', type: String },
    派件地區: { prop: 'deliverDistrict', type: String },
    派件地址: { prop: 'deliverAddress', type: String },
    '無電梯設備的樓宇樓層(派件)': { prop: 'deliverFloor', type: Number },
    貨物描述: { prop: 'goods.description', type: String, required: true },
    '貨物重 (kg)': { prop: 'goods.weight', type: Number, required: true },
    易碎品: { prop: 'goods.fragile', type: (value: any) => value == '是' },
    貨物向上: { prop: 'goods.thisWayUp', type: (value: any) => value == '是' },
    貨物備註: { prop: 'goods.remark', type: String },
    '袋/箱': { prop: 'goods.package', type: (value: any) => (value == '箱' ? 'BOX' : 'BAG') },
  };
}
