import {
  AppConfig,
  AppConfigProvider,
  AppContext,
  AppTheme,
  Config,
  ForgetPasswordModal,
  LoadingIndicator,
  MaintenancePage,
  Order,
  useConfig,
  usePricing,
  User,
  UserRole,
  UserService,
  useStore,
  Utils,
} from '@logistic/common';
import { Layout, message, Space, Typography } from 'antd';
import AppFooter from 'components/AppFooter';
import AppHeader from 'components/AppHeader';
import BatchOrderResultModal from 'components/modal/BatchOrderResultModal';
import CooperateLoginModal from 'components/modal/CooperateLoginModal';
import LoginModal from 'components/modal/LoginModal';
import OrderResultModal from 'components/modal/OrderResultModal';
import RegisterModal from 'components/modal/RegisterModal';
import ResetPasswordModal from 'components/modal/ResetPasswordModal';
import VerifyModal from 'components/modal/VerifyModal';
import FormPage from 'pages/Form';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { analytics, auth, forgotPassword } from 'service/firebase';
import 'styles/client.theme.less';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const CreateOrderPage = lazy(() => import('pages/CreateOrder'));
const FAQPage = lazy(() => import('pages/FAQ'));
const HomePage = lazy(() => import('pages/Home'));
const OrderPage = lazy(() => import('pages/Order'));
const OrderDetailPage = lazy(() => import('pages/OrderDetail'));
const PaymentPage = lazy(() => import('pages/Payment'));
const PaymentDetailPage = lazy(() => import('pages/PaymentDetail'));
const PricingPage = lazy(() => import('pages/Pricing'));
const ProfilePage = lazy(() => import('pages/Profile'));
const InventoryDetailPage = lazy(() => import('pages/InventoryDetail'));

type ClientContextType = {
  user?: User;
  setUser: (user: User) => void;
  order?: Order;
  setOrder: (order: Order | undefined) => void;
  loggedIn: boolean;
  setLoggedIn: (lloggedIn: boolean) => void;
  verified: boolean;
  setVerified: (verified: boolean) => void;
  loginModalVisible: boolean;
  setLoginModalVisible: (visible: boolean) => void;
  cooperateLoginModalVisible: boolean;
  setCooperateLoginModalVisible: (visible: boolean) => void;
  registerModalVisible: boolean;
  setRegisterModalVisible: (visible: boolean) => void;
  resetPasswordModalVisible: boolean;
  setResetPasswordModalVisible: (visible: boolean) => void;
  orderResultModalVisible: boolean;
  setOrderResultModalVisible: (visible: boolean) => void;
  batchOrderResultModalVisible: boolean;
  setBatchOrderResultModalVisible: (visible: boolean) => void;
  verifyModalVisible: boolean;
  setVerifyModalVisible: (visible: boolean) => void;
};

export const ClientContext = React.createContext<ClientContextType>({
  setUser: () => console.log('setUser'),
  setOrder: () => console.log('setOrder'),
  loggedIn: false,
  setLoggedIn: () => console.log('setLoggedIn'),
  verified: false,
  setVerified: () => console.log('setVerified'),
  loginModalVisible: false,
  setLoginModalVisible: () => console.log('setLoginModalVisible'),
  cooperateLoginModalVisible: false,
  setCooperateLoginModalVisible: () => console.log('setCooperateLoginModalVisible'),
  registerModalVisible: false,
  setRegisterModalVisible: () => console.log('setRegisterModalVisible'),
  resetPasswordModalVisible: false,
  setResetPasswordModalVisible: () => console.log('setResetPasswordModalVisible'),
  orderResultModalVisible: false,
  setOrderResultModalVisible: () => console.log('setOrderResultModalVisible'),
  batchOrderResultModalVisible: false,
  setBatchOrderResultModalVisible: () => console.log('setBatchOrderResultModalVisible'),
  verifyModalVisible: false,
  setVerifyModalVisible: () => console.log('setVerifyModalVisible'),
});

const App = () => {
  const [waitingWorker, setWaitingWorker] = useState<ServiceWorker | null>();
  const [initializing, setInitializing] = useState(true);
  const [config, setConfig, configLoaded] = useConfig();
  const [pricing, setPricing, pricingLoaded] = usePricing();
  const [store, setStore, storeLoaded] = useStore();
  const [user, setUser] = useState<User>();
  const [order, setOrder] = useState<Order>();
  const [loggedIn, setLoggedIn] = useState(false);
  const [verified, setVerified] = useState(false);
  const [loginModalVisible, setLoginModalVisible] = useState(false);
  const [cooperateLoginModalVisible, setCooperateLoginModalVisible] = useState(false);
  const [registerModalVisible, setRegisterModalVisible] = useState(false);
  const [forgetPasswordModalVisible, setForgetPasswordModalVisible] = useState(false);
  const [resetPasswordModalVisible, setResetPasswordModalVisible] = useState(false);
  const [orderResultModalVisible, setOrderResultModalVisible] = useState(false);
  const [batchOrderResultModalVisible, setBatchOrderResultModalVisible] = useState(false);
  const [verifyModalVisible, setVerifyModalVisible] = useState(false);
  const location = useLocation();

  useEffect(() => {
    serviceWorkerRegistration.register({ onUpdate: onSWUpdate });
    window.onbeforeunload = () => window.scrollTo(0, 0);
    auth.onAuthStateChanged((user) => {
      if (user) {
        UserService.getUserProfile()
          .then((e) => {
            if ([UserRole.COOPERATE_CUSTOMER, UserRole.INDIVIDUAL_CUSTOMER].includes(e.role)) {
              localStorage.setItem('uid', user.uid);
              localStorage.setItem('username', user.displayName ?? '用戶');
              localStorage.setItem('loggedIn', '1');
              localStorage.setItem('role', e.role);
              analytics.setUserId(user.uid);
              setUser(e);
              setLoggedIn(true);
            } else {
              message.warn('用戶不存在!');
              clearUser();
            }
          })
          .catch(() => clearUser());
      } else {
        clearUser();
      }
    });
    message.config({ top: AppTheme.headerHeight + AppTheme.insetMd, maxCount: 1, prefixCls: `${AppConfig.antPrefixCls}-message` });
  }, []);

  useEffect(() => {
    if (configLoaded && pricingLoaded && (!Utils.isLoggedIn() || user)) {
      setInitializing(false);
      document.querySelector('.loader-container')?.remove();
    }
  }, [configLoaded, pricingLoaded, user, Utils.isLoggedIn()]);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [location.pathname]);

  const onSWUpdate = (registration: ServiceWorkerRegistration) => {
    setWaitingWorker(registration.waiting);
    openNotification();
  };

  const openNotification = () => {
    message.warn(
      <Space prefixCls={`${AppConfig.antPrefixCls}-space`} size={AppTheme.insetSm}>
        {'有新本版可供更新'}
        <Typography.Link onClick={reloadPage} underline>
          {'更新'}
        </Typography.Link>
        <Typography.Link onClick={() => message.destroy()} underline>
          {'略過'}
        </Typography.Link>
      </Space>,
      0
    );
  };

  const reloadPage = () => {
    waitingWorker?.postMessage({ type: 'SKIP_WAITING' });
    window.location.reload(true);
  };

  const clearUser = () => {
    localStorage.removeItem('uid');
    localStorage.removeItem('username');
    localStorage.removeItem('loggedIn');
    localStorage.removeItem('role');
    setUser(undefined);
    setLoggedIn(false);
  };

  if (initializing) return null;
  if (config?.maintenance == 'true') {
    return <MaintenancePage />;
  }

  return (
    <AppConfigProvider>
      <AppContext.Provider
        value={{
          config: config ?? ({ maintenance: 'true' } as Config),
          setConfig: setConfig,
          pricing: pricing ?? [],
          setPricing: setPricing,
          store: store,
          setStore: setStore,
          storeLoaded: storeLoaded,
          forgetPasswordModalVisible: forgetPasswordModalVisible,
          setForgetPasswordModalVisible: setForgetPasswordModalVisible,
        }}
      >
        <ClientContext.Provider
          value={{
            user: user,
            setUser: setUser,
            order: order,
            setOrder: setOrder,
            loggedIn: loggedIn,
            setLoggedIn: setLoggedIn,
            verified: verified,
            setVerified: setVerified,
            loginModalVisible: loginModalVisible,
            setLoginModalVisible: setLoginModalVisible,
            cooperateLoginModalVisible: cooperateLoginModalVisible,
            setCooperateLoginModalVisible: setCooperateLoginModalVisible,
            registerModalVisible: registerModalVisible,
            setRegisterModalVisible: setRegisterModalVisible,
            resetPasswordModalVisible: resetPasswordModalVisible,
            setResetPasswordModalVisible: setResetPasswordModalVisible,
            verifyModalVisible: verifyModalVisible,
            setVerifyModalVisible: setVerifyModalVisible,
            orderResultModalVisible: orderResultModalVisible,
            setOrderResultModalVisible: setOrderResultModalVisible,
            batchOrderResultModalVisible: batchOrderResultModalVisible,
            setBatchOrderResultModalVisible: setBatchOrderResultModalVisible,
          }}
        >
          <Layout>
            <AppHeader />
            <div style={{ flex: 1, paddingTop: AppTheme.headerHeight }}>
              <Suspense fallback={<LoadingIndicator />}>
                <Switch>
                  <Route exact path="/" component={HomePage} />
                  <Route exact path="/create_order" component={CreateOrderPage} />
                  <Route exact path="/faq" component={FAQPage} />
                  <Route exact path="/pricing" component={PricingPage} />
                  <Route exact path="/order/:id" component={OrderDetailPage} />
                  <Route exact path="/form" component={FormPage} />
                  {Utils.isLoggedIn() && <Route exact path="/profile" component={ProfilePage} />}
                  {Utils.isLoggedIn() && <Route exact path="/order" component={OrderPage} />}
                  {Utils.isLoggedIn() && <Route exact path="/payment" component={PaymentPage} />}
                  {Utils.isLoggedIn() && <Route exact path="/payment/:id" component={PaymentDetailPage} />}
                  {Utils.isLoggedIn() && <Route exact path="/inventory/:id" component={InventoryDetailPage} />}
                  <Redirect to="/" />
                </Switch>
              </Suspense>
            </div>
            <AppFooter />
          </Layout>
          <LoginModal />
          <CooperateLoginModal />
          <RegisterModal />
          <ForgetPasswordModal forgotPassword={forgotPassword} />
          <ResetPasswordModal />
          <VerifyModal />
          <OrderResultModal />
          <BatchOrderResultModal />
        </ClientContext.Provider>
      </AppContext.Provider>
    </AppConfigProvider>
  );
};

export default App;
