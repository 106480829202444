import Icon from '@ant-design/icons';
import React from 'react';
import { ReactComponent as Account } from '../assets/img/ic_account.svg';
import { ReactComponent as Bookmark } from '../assets/img/ic_bookmark.svg';
import { ReactComponent as BookmarkFilled } from '../assets/img/ic_bookmark_filled.svg';
import { ReactComponent as Block } from '../assets/img/ic_block.svg';
import { ReactComponent as CheckBox } from '../assets/img/ic_checkbox.svg';
import { ReactComponent as Close } from '../assets/img/ic_close.svg';
import { ReactComponent as DropdownOpen } from '../assets/img/ic_dropdown_open.svg';
import { ReactComponent as Email } from '../assets/img/ic_email.svg';
import { ReactComponent as Facebook } from '../assets/img/ic_facebook.svg';
import { ReactComponent as Instagram } from '../assets/img/ic_instagram.svg';
import { ReactComponent as OfficeHour } from '../assets/img/ic_office_hour.svg';
import { ReactComponent as Phone } from '../assets/img/ic_phone.svg';
import { ReactComponent as Signal } from '../assets/img/ic_signal.svg';
import { ReactComponent as Telegram } from '../assets/img/ic_telegram.svg';
import { ReactComponent as WeChat } from '../assets/img/ic_wechat.svg';
import { ReactComponent as Whatsapp } from '../assets/img/ic_whatsapp.svg';

export const AccountIcon = (props?: CustomIconComponentProps) => <Icon component={Account} {...props} />;
export const BookmarkIcon = (props?: CustomIconComponentProps) => <Icon component={Bookmark} {...props} />;
export const BookmarkFilledIcon = (props?: CustomIconComponentProps) => <Icon component={BookmarkFilled} {...props} />;
export const BlockIcon = (props?: CustomIconComponentProps) => <Icon component={Block} {...props} />;
export const CheckBoxIcon = (props?: CustomIconComponentProps) => <Icon component={CheckBox} {...props} />;
export const CloseIcon = (props?: CustomIconComponentProps) => <Icon component={Close} {...props} />;
export const DropdownOpenIcon = (props?: CustomIconComponentProps) => <Icon component={DropdownOpen} {...props} />;
export const EmailIcon = (props?: CustomIconComponentProps) => <Icon component={Email} {...props} />;
export const FacebookIcon = (props?: CustomIconComponentProps) => <Icon component={Facebook} {...props} />;
export const InstagramIcon = (props?: CustomIconComponentProps) => <Icon component={Instagram} {...props} />;
export const WhatsappIcon = (props?: CustomIconComponentProps) => <Icon component={Whatsapp} {...props} />;
export const TelegramIcon = (props?: CustomIconComponentProps) => <Icon component={Telegram} {...props} />;
export const SignalIcon = (props?: CustomIconComponentProps) => <Icon component={Signal} {...props} />;
export const WeChatIcon = (props?: CustomIconComponentProps) => <Icon component={WeChat} {...props} />;
export const OfficeHourIcon = (props?: CustomIconComponentProps) => <Icon component={OfficeHour} {...props} />;
export const PhoneIcon = (props?: CustomIconComponentProps) => <Icon component={Phone} {...props} />;

interface CustomIconComponentProps {
  width?: string | number;
  height?: string | number;
  fill?: string;
  viewBox?: string;
  className?: string;
  style?: React.CSSProperties;
}
