import { Typography } from 'antd';
import { AppConfigProvider } from 'components/AppLayout';
import maintenance from 'img/maintenance.png';
import React from 'react';
import AppTheme from 'theme/theme';

const MaintenancePage = () => {
  return (
    <AppConfigProvider>
      <div
        style={{
          flex: 1,
          minHeight: 800,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img src={maintenance} />
        <Typography.Title style={{ marginTop: AppTheme.insetLg }} level={2}>
          {'對不起，系統維修中'}
        </Typography.Title>
        <Typography.Text style={{ marginTop: AppTheme.insetXs }}>{'請待會再次進來'}</Typography.Text>
      </div>
    </AppConfigProvider>
  );
};

export default MaintenancePage;
